import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import moment from 'moment';
import Acceptpopup from './Acceptpopup';
import { useSelector, useDispatch } from 'react-redux';
import { setReturncomponent, setEstimatedata, storeInLocalStore } from 'store/reducers/mainSlice';
import 'react-datepicker/dist/react-datepicker.css';
import { FaArrowRight, FaEdit, FaMinus, FaPlus, FaRegSave, FaSave, FaPaperPlane } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import { FaCheck, FaDownload, FaPen, FaRegTrashCan } from 'react-icons/fa6';
import EstimateDetailInvoice from './EstimateDetailInvoice';
import PaymentsComponent from './paymentComponents/PaymentsComponent';
import ShipmentsComponent from './shipmentComponents/NewShipmentComponent';
import { LuChevronDown } from 'react-icons/lu';
import { Dropdown } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import Notes from 'ui-component/notes';
import PrivacyModeContent from 'ui-component/PrivacyModeContent';
import ActionConfirmationModal from 'ui-component/confirmation-dialogs/actionConfirmationModal/ActionConfirmationModal';
import { PiInfoFill } from 'react-icons/pi';
import { getCurrencySymbol } from 'utils/currency';
import config from 'config.js';
import { useQuery, useQueryClient } from 'react-query';
import { getLocalStorageCacheItem, setLocalStorageCacheItem } from 'helpers/common.helper';
import md5 from 'md5-hash';
import AcceptOrderModal from 'ui-component/confirmation-dialogs/acceptOrderModal/AcceptOrderModal';
import DeleteConfirmation from 'ui-component/confirmation-dialogs/deleteConfirmation/DeleteConfirmation';
import agreementImage from 'assets/images/agreement.svg';
import StatusBadge from 'ui-component/StatusBadge';
import AddToPurchaseListConfirmation from 'ui-component/confirmation-dialogs/addToPurchaseListConfirmation/AddToPurchaseListConfirmation';

import SendEmailModal from './modals/SendEmailModal';
import PurchaseOrderModal from './modals/PurchaseOrderModal';
import TimelineStep from 'ui-component/TimelineStep';
// import AWBModal from './modals/AWBModal';
// import InvoiceIcon from 'assets/images/icons/invoice-icon.svg';
import { GoChecklist } from "react-icons/go";
import { RegisterPaymentIcon, SalesOrderIcon } from 'ui-component/custom-icons/icons';
import InvoiceComponent from './components/InvoiceComponent';
import QoutationComponent from './components/QoutationComponent';
import SalesOrderComponent from './components/SalesOrderComponent';

const EstimationDetails = () => {
	const { id } = useParams();
	const [userName] = useSelector((state) => [state.main.user.first_name + ' ' + state.main.user.last_name]);
	const userEmail = useSelector((state) => state.main.user?.email);

	const [isInternalLoading, setInternalLoading] = useState(false);

	// Customer Create
	const [selectedEstimate, setSelectedEstimate] = useState();
	const [salesTypePath, setSalesTypePath] = useState('');
	const [salesType, setSalesType] = useState('');
	const [addToPl, setAddToPl] = useState(null);
	const [addToPlId, setAddToPlId] = useState(null);
	const [showActions, setShowActions] = useState(false);
	const [showDeletePopup, setShowDeletePopup] = useState(false);
	const [openSendEmailPopup, setOpenSendEmailPopup] = useState(false);
	const [sendingEstimate, setSendingEstimate] = useState(false);
	const [selectedForSending, setSelectedForSending] = useState('');
	const [showPurchaseOrderModal, setShowPurchaseOrderModal] = useState(null);
	const [purchaseOrderItems, setPurchaseOrderItems] = useState(null);
	const [showCancelPopup, setShowCancelPopup] = useState(false);

	const currentDate = moment().format('YYYY-MM-DD');

	const statusAllowedToEdit = [
		'Estimated',
		'Pending Manager',
		'Paid',
		'Pending',
		// 'Accepted',
		'Draft',
		'Credit Note',
		'Open',
		'Unprocessed'
	];
	// const statusAllowedToApprove = ['Pending Customer', 'Pending Manager'];
	// const statusAllowedToCancel = ['Estimated', 'Pending Manager', 'Pending Customer'];
	const statusAllowedToDeleteDraft = ['Draft'];
	const statusAllowedToPayAndSalesOrder = ['Draft', 'Credit Note'];
	const statusAllowedToAccept = ['Pending', 'Pending Manager'];
	const statusAllowedToApprove = ['Accepted'];
	const statusAllowedToCancel = ['Open', 'Pending Manager', 'Pending'];

	const dispatch = useDispatch();

	// End Create
	const history = useNavigate();
	const [modalPrice, setModalprice] = useState('00');

	const [showModal, setShowModal] = useState(false);
	const [shippingRow, setShippingrow] = useState({
		quantity: 1,
		unitPrice: 0,
		totalPrice: 0
	});

	const app_name = config.apps.find((c) => c?.app_urls.includes(window.location.origin))?.app_name || 'abcd';
	// const client_name = config.apps.find((c) => c?.app_urls.includes(window.location.origin))?.client_name || 'abcd';

	const [selectedCustomerinfo, setSelectedCustomerInfo] = useState({});
	const [subTotal, setSubtotal] = useState('00');
	const [grandTotal, setGrandtotal] = useState('00');
	const [alertMessage, setAlertMessage] = useState();
	const [shipmentData, setShipmentData] = useState([]);
	const [paymentData, setPaymentData] = useState([]);
	// const [awbData, setAwbData] = useState([]);
	const [estimateDetails, setEstimateDetails] = useState();
	const [isDisable, setDisable] = useState(false);
	const [deleteMessage, setDeleteMessage] = useState('');

	// const [orderType, setOrderType] = useState('');
	const [orderAgreementsData, setOrderAgreementsData] = useState([]);
	const [contactData, setContactData] = useState(null);
	// const [showAWBModal, setShowAWBModal] = useState(false);
	const [selectedPaymentShipment, setSelectedPaymentShipment] = useState('payment');
	const queryClient = useQueryClient();
	const params = useParams();
	const location = useLocation();
	const pathParts = location.pathname.split('/');
	const currentPath = pathParts[pathParts.length - 1];
	const mainPath = pathParts.slice(0, 3).join('/');

	const openSendEmailModal = (type) => {
		setOpenSendEmailPopup(type);
	};

	const closeSendEmailPopup = () => {
		setOpenSendEmailPopup(false);
	};

	const handleSelectMenu = (item) => {
		setSelectedPaymentShipment(item);
		console.log('selectedPaymentShipment', selectedPaymentShipment);
	};

	const { data: estDetReqData } = useQuery(
		['estimation-details', id, location, salesTypePath, showActions],
		() => {
			// setSalesTypePath(mainPath)
			// console.log('asd', id)
			// console.log('pathParts', pathParts)
			// console.log('mainPath', mainPath)
			const queryParams = new URLSearchParams(location.search);
			// Get a specific query parameter
			const callbackParam = queryParams.get('callback-action-accept-order');
			// if (callbackParam) {
			// 	if (callbackParam == 'sucess') {
			// 		toast.promise(
			// 			api.getEstimationByIdWithParam(id, callbackParam).then(
			// 				(res) => {
			// 					// if (res.status) {
			// 					// 	setEstimaterow((oldData) => ({
			// 					// 		...oldData,
			// 					// 		notes: [
			// 					// 			{
			// 					// 				id: res?.data?.id,
			// 					// 				details: noteInputValue,
			// 					// 				date: moment(res?.data?.created_at).format('YYYY-MM-DD'),
			// 					// 				time: moment(res?.data?.created_at).format('HH:mm'),
			// 					// 				user_name: userName,
			// 					// 				expand: false,
			// 					// 				isDeletable: true
			// 					// 			},
			// 					// 			...oldData?.notes
			// 					// 		]
			// 					// 	}));
			// 					// }

			// 					return 'Estimate Accepted';
			// 				},
			// 				() => { }
			// 			),
			// 			{
			// 				loading: 'Accepting Estimate...',
			// 				success: `Estimate ${callbackParam}`,
			// 				error: <b>Failed to accept estimatimate</b>
			// 			}
			// 		);
			// 	}
			// 	queryParams.delete('callback-action-accept-order');

			// 	history(`${salesTypePath}/${id}`);
			// }
			if (mainPath == '/sales/estimates' || mainPath == '/sales/quotations') {
				setShowActions(true);
				return api.getQoutationById(id);
			} else {
				setShowActions(true);
				return api.getEstimationById(id);
			}
		},
		{
			keepPreviousData: true,
			initialData: (e) => {
				const item = getLocalStorageCacheItem(md5(`estimation-details-${id}`));
				return item ? item : undefined;
			},
			onSuccess: (e) => {
				setLocalStorageCacheItem(md5(`estimation-details-${id}`), e, moment().add(3, 'days').format('YYYY-MM-DD'));
			}
		}
	);

	const invalidateEstQuery = () => {
		queryClient.invalidateQueries({ queryKey: ['estimation-details', id, location, salesTypePath, showActions] });
	};

	const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);

	const [confirmationDialog, setConfirmationDialog] = useState({
		show: false,
		message: '',
		title: '',
		handleConfirm: () => {},
		handleClose: () => {}
	});

	const handleCloseConfirmationDialog = () => {
		setConfirmationDialog({
			show: false,
			message: '',
			title: '',
			handleConfirm: () => {},
			handleClose: () => {}
		});
	};

	const showConfirmationDialog = (
		title,
		message = '',
		onConfirm = () => {},
		onClose = () => {
			handleCloseConfirmationDialog();
		}
	) => {
		setConfirmationDialog({
			show: true,
			message: message,
			title: title,
			handleConfirm: onConfirm,
			handleClose: onClose
		});
	};
	const handleCloseDeletePopup = () => setShowDeletePopup('');

	const handleSendEstimateEmail = (data) => {
		// setSendingEstimate(true)
		// return false;
		// setOpenSendEmailPopup(false)
		setSendingEstimate(true);
		toast.promise(
			api.sendEstimateByEmail(data).then(
				(res) => {
					invalidateEstQuery();
					// console.log('het')
					// return false
					if (res.status) {
						setOpenSendEmailPopup(false);
					} else {
						toast.error(`Sending email failed. Please try again.`);
					}
					setSendingEstimate(false);
					// setIsProcessing(false)

					return res?.status ? res : null;
				},
				() => {}
			),
			{
				loading: `Sending...`,
				success: (data) => `${data.message}`,
				error: <b>Failed to send email</b>
			}
		);
	};

	const handlePlDialogClose = () => {
		setAddToPl(null);
		setAddToPlId(null);
	};
	const handleAddPayment = (data) => {
		invalidateEstQuery();
	};

	const handlePaymentToggle = (index) => {
		setPaymentData((oldData) =>
			oldData?.map((p, i) =>
				i == index
					? {
							...p,
							expand: p?.expand ? false : true
					  }
					: p
			)
		);
	};

	const acceptpopup = (id) => {
		setShowModal(id);
	};

	const addtopurchaselistold = (id) => {
		api.createpurchase(id).then((res) => {
			if (res.status) {
				history(`${salesTypePath}`);
			}
		});
	};

	const addToPurchaseList = (id) => {
		setAddToPlId(id);
		const addToPL = selectedEstimate?.estimation_details?.map((prod) => {
			return {
				id: prod?.product?.id,
				name: prod?.product?.product_name,
				qty: prod?.qty
			};
		});
		setAddToPl(addToPL);
	};

	const addPurchaseOrder = (id) => {

		toast.promise(
			api
				.createDraftPurchaseOrder({ estimation_id: id })
				.then(
					(res) => {
						if (res.status) {
							history(`/purchasing/view/${res?.data?.purchase_invoice_id}`);
						}

						return res?.status ? res : null;
					},
					() => { }
				),
			{
				loading: 'Creating purchase order...',
				success: (data) => `${data.message}`,
				error: <b>Failed to create purchase order</b>
			}
		);

		// setShowPurchaseOrderModal(id);

		// const items = selectedEstimate?.estimation_details?.map((prod) => {
		// 	return {
		// 		id: prod?.product?.id,
		// 		name: prod?.product?.product_name,
		// 		qty: prod?.qty
		// 	};
		// });
		// setPurchaseOrderItems(items);
	}

	const closePurchaseOrderPopup = () => {
		setShowPurchaseOrderModal('');
	};

	const handleUpdatePayment = (data) => {
		invalidateEstQuery();
	};

	const handleDeletePayment = (data) => {
		invalidateEstQuery();
	};

	const [estimateRow, setEstimaterow] = useState({
		customer_id: '',
		estimate_number: '',
		sales_manager: '',
		transit_id: '',
		vatStatus: 'Including VAT',
		vatPrice: 21,
		startDate: currentDate,
		expiresIn: 7,
		currency: 'eur',
		notes: []
	});
	// Row Area
	const [rows, setRows] = useState([{ id: 1, modelId: '', type: '', quantity: 0, unitPrice: 0, total: 0 }]);
	const [nextId, setNextId] = useState(2);
	const options = {
		fields: ['address_components', 'geometry', 'icon', 'name'],
		types: ['establishment']
	};

	//   End Row
	/* Shipping Calculator */

	useEffect(() => {
		setEstimaterow((oldData) => ({ ...oldData, sales_manager: selectedCustomerinfo?.sales_manager_id }));
	}, [selectedCustomerinfo]);

	const [currentAppName, setCurrentAppName] = useState();
	const [currentClientName, setCurrentClientName] = useState();

	useEffect(() => {
		initiateDatas();
		setCurrentAppName(selectedEstimate?.settings?.client_code);
		setCurrentClientName(selectedEstimate?.settings?.client_name);

		const subPath = pathParts;
		subPath.pop();

		setSalesTypePath(subPath.join('/'));
	}, [location]);

	// const getSettings = () => {
	// 	api.getSettings().then((res) => {
	// 		//
	// 	});
	// };

	useEffect(() => {
		if (estDetReqData?.data?.data) {
			// getSettings()
			setSelectedEstimate(estDetReqData?.data?.data);
			if (estDetReqData?.data?.data?.payments) {
				setPaymentData([...estDetReqData?.data?.data?.payments]);
			}
			// if (estDetReqData?.data?.data?.shipments) {
			// 	setShipmentData([...estDetReqData?.data?.data?.awb]);
			// }
			if (salesTypePath == '/sales/estimates' || salesTypePath == '/sales/quotations') {
				setSalesType('estimate');
			} else {
				// if (selectedEstimate?.invoice_number == '' && selectedEstimate?.estimation_number != '') {
				// 	setSalesType('selectedEstimate');
				// } else
				if (selectedEstimate?.invoice_number != '' && selectedEstimate?.estimation_number != '') {
					setSalesType('invoice');
				} else {
					setSalesType('credit-note');
				}
			}
			// if (selectedEstimate?.invoice_number == '' && selectedEstimate?.estimation_number != '') {
			// 	setSalesType('selectedEstimate');
			// } else if (selectedEstimate?.invoice_number != '' && selectedEstimate?.estimation_number != '') {
			// 	setSalesType('invoice');
			// } else {
			// 	setSalesType('credit-note');
			// }
			setContactData(estDetReqData?.data?.data.customer);
			setOrderAgreementsData(estDetReqData?.data?.data.order_agreements);
		}
	}, [estDetReqData]);

	const initiateDatas = () => {
		if (id) {
		} else {
			history(salesTypePath);
		}
	};

	const handleCreatePurchaseOrder = (details, items) => {
		// console.log('details', details);
		// console.log('items', items);
		let data = details;
		data['items'] = items;
		// console.log(data)

		// toast.promise(
		// 	api
		// 		.createInvoicePurchaseOrder(data)
		// 		.then(
		// 			(res) => {
		// 				if (res.status) {
		// 					invalidateEstQuery();
		// 					closePurchaseOrderPopup()
		// 				}

		// 				return res?.status ? res : null;
		// 			},
		// 			() => { }
		// 		),
		// 	{
		// 		loading: 'Creating purchase order...',
		// 		success: (data) => `${data.message}`,
		// 		error: <b>Failed to create purchase order</b>
		// 	}
		// );

	}

	const addNote = (noteInputValue, noteType, mentions, noteInputPlain) => {
		toast.promise(
			api
				.newEstimateNote(id, {
					details: noteInputValue,
					detail_plain: noteInputPlain,
					type: 'public',
					todo: noteType == 'todo' ? true : false,
					mentions
				})
				.then(
					(res) => {
						if (res.status) {
							setEstimaterow((oldData) => ({
								...oldData,
								notes: [
									{
										id: res?.data?.id,
										details: noteInputValue,
										date: moment(res?.data?.created_at).format('YYYY-MM-DD'),
										time: moment(res?.data?.created_at).format('HH:mm'),
										user_name: userName,
										expand: false,
										isDeletable: true
									},
									...oldData?.notes
								]
							}));
						}

						return res?.status ? res : null;
					},
					() => {}
				),
			{
				loading: 'Adding Note...',
				success: (data) => `${data.message}`,
				error: <b>Failed to add note</b>
			}
		);
	};

	const toggleNoteExpand = (index) => {
		setEstimaterow((oldData) => ({
			...oldData,
			notes: oldData?.notes?.map((note, i) =>
				index == i
					? {
							...note,
							expand: !note?.expand
					  }
					: note
			)
		}));
	};

	const handleAddToPurchaseList = (showId, items) => {
		toast.promise(
			api.createbackorder(addToPlId, items).then((res) => {
				// console.log('res', res);
				if (res.status) {
					invalidateEstQuery();
					setAddToPl(null);
					setAddToPlId(null);
				}

				return res?.status == true ? res : null;
			}),
			{
				loading: 'Adding to purchase list...',
				success: (data) => `${data.message}`,
				error: <b>Could not add to purchase list</b>
			}
		);
	};

	const removeNote = (index) => {
		toast.promise(
			api.removeEstimateNote(id, estimateRow?.notes?.[index]?.id).then(
				(res) => {
					if (res.status) {
						setEstimaterow((oldData) => ({
							...oldData,
							notes: oldData?.notes?.filter((note, i) => index != i)
						}));
					}

					return res?.status ? res : null;
				},
				() => {}
			),
			{
				loading: 'Deleting Note...',
				success: (data) => `${data.message}`,
				error: <b>Failed to delete note</b>
			}
		);
	};

	useEffect(() => {
		if (selectedEstimate) {
			const expDate = moment(selectedEstimate?.expires_at).format('YYYY-MM-DD');
			let tempRow = selectedEstimate?.estimation_details.map((item, index) => {
				return {
					id: index,
					modelId: item?.product_id,
					type: item?.product?.product_name,
					quantity: item?.qty,
					unitPrice: Number(item?.unit_price),
					total: item?.total_price
				};
			});

			tempRow = [
				...tempRow,
				{
					id: tempRow.length + 1,
					modelId: '',
					type: '',
					quantity: '',
					unitPrice: '',
					total: ''
				}
			];

			setRows(tempRow);
			setNextId(tempRow.length + 1);
			setSelectedCustomerInfo(selectedEstimate.customer);
			setSubtotal(selectedEstimate?.subtotal);

			const temEstimate = {
				estimate_number: selectedEstimate?.estimation_number,
				customer_id: selectedEstimate?.customer_id,
				startDate: selectedEstimate?.start_date,
				expiryDate: expDate,
				transit_id: selectedEstimate?.transit_company?.id,
				sales_manager: selectedEstimate?.sales_person?.id,
				private_note: selectedEstimate?.private_note,
				vatStatus: selectedEstimate?.vat_type,
				vatPrice: selectedEstimate?.vat_percent || 21,
				currency: selectedEstimate?.currency,
				private_note: selectedEstimate?.private_note,
				expiresIn: moment(selectedEstimate?.expires_at).diff(selectedEstimate?.start_date, 'days')
			};

			let notesArray = [];

			selectedEstimate?.notes?.forEach((note) => {
				notesArray = [
					{
						id: note?.id,
						details: note?.details,
						date: moment(note?.created_at).format('YYYY-MM-DD'),
						time: moment(note?.created_at).format('HH:mm'),
						user_name: `${note?.user_created?.first_name} ${note?.user_created?.last_name}`,
						expand: false,
						isDeletable: note?.is_deletable
					},
					...notesArray
				];
			});

			notesArray = _.sortBy(notesArray, (n) => -n.id);

			const tempShipping = {
				unitPrice: selectedEstimate?.shipping_fees,
				quantity: selectedEstimate?.shipping_qty,
				totalPrice: Number(selectedEstimate?.shipping_fees) * Number(selectedEstimate?.shipping_qty)
			};
			setEstimaterow({ ...temEstimate, notes: notesArray });
		}
	}, [selectedEstimate]);

	const calculateTotal = () => {
		let total = 0;
		let grandTotal = 0;
		for (let i = 0; i < rows.length; i++) {
			const { quantity, unitPrice } = rows[i];
			total += parseFloat(quantity || 0) * parseFloat(unitPrice || 0);
		}

		if (shippingRow?.totalPrice > 0) {
			const shippingTotal = parseFloat(shippingRow.totalPrice);
			grandTotal = total + shippingTotal;
		} else {
			grandTotal = total;
		}

		if (isNaN(grandTotal)) {
			return { subtotal: '00', modelTotal: '00' };
		}
		return { subtotal: grandTotal, modelTotal: total };
	};

	useEffect(() => {
		let tempsubtotal = calculateTotal();
		const subTotals = parseFloat(tempsubtotal?.subtotal).toFixed(2);
		const modaltotal = parseFloat(tempsubtotal?.modelTotal).toFixed(2);
		setSubtotal(subTotals);
		setModalprice(modaltotal);
	}, [rows, shippingRow, estimateRow]);

	useEffect(() => {
		if (estimateRow?.vatStatus == 'Excluding VAT' && estimateRow?.vatPrice > 0) {
			let tempvat = (modalPrice * (estimateRow?.vatPrice / 100)).toFixed(2);
			let temTotal = (parseFloat(subTotal) + parseFloat(tempvat)).toFixed(2);
			setGrandtotal(temTotal);
		} else {
			setGrandtotal(subTotal);
		}
	}, [subTotal, modalPrice, estimateRow]);

	const downloadEstimate = (template, to) => {
		// toast.promise(
		api.downloadEstimate(id, { template, to }).then((res) => {
			// if (res?.status) {

			var newBlob = new Blob([res], { type: 'application/pdf' });
			saveAs(
				newBlob,
				`${
					selectedEstimate?.transaction_type == 'Invoice'
						? 'I-' + selectedEstimate?.invoice_number
						: 'E-' + selectedEstimate?.estimation_number
				}.pdf`
			);
		});
	};

	// Create Customer

	const changeStatus = (data) => {
		const id = data.id;
		const status = data.status;
		showConfirmationDialog('Update Status', 'Are You sure you want to update the status of this order?', () => {
			api.changeestimateStatus({ estimation_id: id, status: status }).then((res) => {
				if (res.status) {
					initiateDatas();
				}
				handleCloseConfirmationDialog();
				invalidateEstQuery();
				return res?.status ? res : abc;
			}),
				{
					loading: 'Updating Invoice Status...',
					success: (data) => `${data.message}`,
					error: <b>Failed to update status</b>
				};
		});
	};

	const redirectFunc = (data) => {
		if (data?.bankDetails == true && data?.ttCopies == true && data?.vatRegistration == true) {
			dispatch(setReturncomponent('Accepted'));
			setTimeout(() => {
				history(salesTypePath);
			}, 1000);
		} else {
			dispatch(setReturncomponent('Estimated'));
			setTimeout(() => {
				history(salesTypePath);
			}, 1000);
		}
	};
	const handleClose = (data) => {
		setShowModal('');
		setShowCancelPopup(false)
	};

	const [showAcceptPopup, setShowAcceptPopup] = useState(null);

	const acceptPopup = () => {
		setShowAcceptPopup({
			id: selectedEstimate?.id
		});
	};

	const handleAcceptConfirm = () => {
		toast.promise(
			api
				.acceptedEstimate({
					estimation_id: selectedEstimate?.id
				})
				.then((res) => {
					if (res.status) {
						setShowAcceptPopup(null);
						invalidateEstQuery();
					}
				}),
			{
				loading: 'Accepting Estimate...',
				success: <b>Estimate Accepted!</b>,
				error: <b>Could not Accept Estimate.</b>
			}
		);
	};

	const acceptOrder = (data) => {
		const id = data.id;
		toast.promise(
			api
				.acceptedEstimate({
					qoutation_id: id
				})
				.then((res) => {
					if (res.status) {
						// handleCloseConfirmationDialog();
						// invalidateEstQuery();

						history(`/sales/sales-orders/${res?.data?.sales_order_id}`);
						// history(`/sales/invoices/${res?.data?.invoice_id}`);
					}
				}),
			{
				loading: 'Accepting Quotation...',
				success: <b>Quotation Accepted</b>,
				error: <b>Could not accept quotation.</b>
			}
		);
	};
	const createSalesOrder = (data) => {
		const id = data.id;
		toast.promise(
			api
				.createSalesOrderFromInvoice({
					estimation_id: id
				})
				.then((res) => {
					if (res.status) {
						// handleCloseConfirmationDialog();
						// invalidateEstQuery();
						history(`/sales/sales-orders/${res?.data?.sales_order_id}`);
					}
				}),
			{
				loading: 'Creating Sales Order...',
				success: <b>Sales Order Created</b>,
				error: <b>Could not create sales order.</b>
			}
		);
	};

	const billOrder = (data) => {
		const id = data.id;
		toast.promise(
			api
				.approveEstimate({
					qoutation_id: id
				})
				.then((res) => {
					if (res.status) {
						// handleCloseConfirmationDialog();
						// invalidateEstQuery();
						history(`/sales/invoices/${res?.data?.invoice_id}`);
					}
				}),
			{
				loading: 'Creating Invoice...',
				success: <b>Invoice Created</b>,
				error: <b>Could not create invoice.</b>
			}
		);
	};




	const approveOrder = (data) => {
		const id = data.id;
		toast.promise(
			api
				.approveEstimate({
					qoutation_id: id
				})
				.then((res) => {
					if (res.status) {
						// handleCloseConfirmationDialog();
						invalidateEstQuery();
						history(`/sales/invoices/${res?.data?.invoice_id}`);
					}
				}),
			{
				loading: 'Invoicing Estimate...',
				success: <b>Estimate has been invoiced</b>,
				error: <b>Could not process estimate.</b>
			}
		);
	};

	const cancelEstimate = (data) => {
		const id = data.id;
		toast.promise(
			api.cancelEstimation(id).then((res) => {
				if (res.status) {
					// handleCloseConfirmationDialog();
					invalidateEstQuery();
					history(`/sales/quotations`);
				}
			}),
			{
				loading: 'Canceling Quotation...',
				success: <b>Quotation cancelled</b>,
				error: <b>Could not cancel quotation.</b>
			}
		);
	};

	const cancelInvoice = (data) => {
		const id = data.id;
		toast.promise(
			api.cancelInvoice(id).then((res) => {
				if (res.status) {
					// handleCloseConfirmationDialog();
					invalidateEstQuery();
					history(`/sales/invoices`);
				}
			}),
			{
				loading: 'Canceling Invoice...',
				success: <b>Invoice cancelled</b>,
				error: <b>Could not cancel invoice.</b>
			}
		);
	};

	const deleteEstimate = (data) => {
		setDeleteMessage(
			`Are you sure you want to delete <br />${salesType == 'estimate' ? 'quotation' : $salesType } ${salesType == 'estimate' ? data?.estimation_number : data?.invoice_number}?`
		);
		setShowDeletePopup(data);
	};

	// const openAWBModal = () => {
	// 	setShowAWBModal(true);
	// };

	// const closeAWBModal = () => {
	// 	setShowAWBModal(false);
	// };

	const handleDelete = (id) => {
		if (salesType == 'estimate') {
			toast.promise(
				// api.deleteInvoice(id).then((res) => {
				api.deleteEstimation(id).then((res) => {
					if (res.status) {
						// handleCloseConfirmationDialog();
						invalidateEstQuery();
						history(`/sales/quotation`);
					}
				}),
				{
					loading: 'Deleting Quotation...',
					success: <b>Quotation deleted</b>,
					error: <b>Could not delete quotation.</b>
				}
			);
		} else {
			toast.promise(
				// api.deleteEstimation(id).then((res) => {
				api.deleteInvoice(id).then((res) => {
					if (res.status) {
						// handleCloseConfirmationDialog();
						invalidateEstQuery();
						history(`/sales/invoices`);
					}
				}),
				{
					loading: 'Deleting Invoice...',
					success: <b>Invoice deleted</b>,
					error: <b>Could not delete Invoice.</b>
				}
			);
		}

	};

	const handleCancel = () => {
		console.log('salesType', salesType);
		if (salesType === 'invoice') {
			cancelInvoice(selectedEstimate);
		} else {
			cancelEstimate(selectedEstimate);
		}
		setShowCancelPopup(false);
	}

	return (
		<>
			<div className="row m-0 w-100 est-nv-container">
				<div className="col-12 col-md-8 p-0 p-md-2 inv-preview">
					<div className="section-head-new pl-0 mb-1 mt-2">
						<div className="actions" style={{ gap: '5px', width: 'auto' }}>
							{showActions && (
								<>
									{selectedEstimate?.master_status?.status_type_id == 2 ? (
										<>
											<button
												type="button"
												className="btn btn-primary btn-outlined-main white-var"
												style={{ minWidth: 'unset' }}
												onClick={() => {
													openSendEmailModal('quotation');
													setSelectedForSending(selectedEstimate?.id);
												}}
												disabled={isInternalLoading || !estimateRow?.estimate_number}
											>
												<svg
													id="fi_2983788"
													enable-background="new 0 0 512.005 512.005"
													height="18"
													viewBox="0 0 512.005 512.005"
													width="18"
													xmlns="http://www.w3.org/2000/svg"
												>
													<g>
														<path
															d="m511.658 51.675c2.496-11.619-8.895-21.416-20.007-17.176l-482 184c-5.801 2.215-9.638 7.775-9.65 13.984-.012 6.21 3.803 11.785 9.596 14.022l135.403 52.295v164.713c0 6.948 4.771 12.986 11.531 14.593 6.715 1.597 13.717-1.598 16.865-7.843l56.001-111.128 136.664 101.423c8.313 6.17 20.262 2.246 23.287-7.669 127.599-418.357 122.083-400.163 122.31-401.214zm-118.981 52.718-234.803 167.219-101.028-39.018zm-217.677 191.852 204.668-145.757c-176.114 185.79-166.916 176.011-167.684 177.045-1.141 1.535 1.985-4.448-36.984 72.882zm191.858 127.546-120.296-89.276 217.511-229.462z"
															fill="currentColor"
														></path>
													</g>
												</svg>
												Send
											</button>
											<button
												type="button"
												className="btn btn-primary btn-outlined-main white-var"
												style={{ minWidth: 'unset' }}
												disabled={isInternalLoading || !estimateRow?.estimate_number}
												onClick={() => {
													downloadEstimate('estimate', 'customer');
												}}
											>
												<svg
													id="fi_2767144"
													enable-background="new 0 0 128 128"
													height="18"
													viewBox="0 0 128 128"
													width="18"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														id="Download"
														d="m128 65c0 15.439-12.563 28-28 28h-20c-2.211 0-4-1.791-4-4s1.789-4 4-4h20c11.027 0 20-8.973 20-20s-8.973-20-20-20h-4c-2.211 0-4-1.791-4-4 0-15.439-12.563-28-28-28s-28 12.561-28 28c0 2.209-1.789 4-4 4h-4c-11.027 0-20 8.973-20 20s8.973 20 20 20h20c2.211 0 4 1.791 4 4s-1.789 4-4 4h-20c-15.437 0-28-12.561-28-28s12.563-28 28-28h.223c1.996-17.975 17.277-32 35.777-32s33.781 14.025 35.777 32h.223c15.438 0 28 12.561 28 28zm-50.828 37.172-9.172 9.172v-50.344c0-2.209-1.789-4-4-4s-4 1.791-4 4v50.344l-9.172-9.172c-1.563-1.563-4.094-1.563-5.656 0s-1.563 4.094 0 5.656l16 16c.781.781 1.805 1.172 2.828 1.172s2.047-.391 2.828-1.172l16-16c1.563-1.563 1.563-4.094 0-5.656s-4.094-1.563-5.656 0z"
														fill="currentColor"
													></path>
												</svg>
												Download
											</button>
											{statusAllowedToEdit.includes(selectedEstimate?.master_status?.name) && (
												<PermissionAllowed permissions={['Edit Estimation (Button)']}>
													<button
														type="button"
														className="btn btn-primary btn-outlined-main white-var"
														style={{ minWidth: 'unset' }}
														disabled={isInternalLoading || !estimateRow?.estimate_number}
														onClick={() => {
															history(`${salesTypePath}/edit/${id}`);
														}}
													>
														<svg
															id="fi_2356780"
															style={{ marginTop: '-3px' }}
															enable-background="new 0 0 511.984 511.984"
															height="16"
															viewBox="0 0 511.984 511.984"
															width="16"
															xmlns="http://www.w3.org/2000/svg"
														>
															<g>
																<path
																	d="m415 221.984c-8.284 0-15 6.716-15 15v220c0 13.785-11.215 25-25 25h-320c-13.785 0-25-11.215-25-25v-320c0-13.785 11.215-25 25-25h220c8.284 0 15-6.716 15-15s-6.716-15-15-15h-220c-30.327 0-55 24.673-55 55v320c0 30.327 24.673 55 55 55h320c30.327 0 55-24.673 55-55v-220c0-8.284-6.716-15-15-15z"
																	fill="currentColor"
																></path>
																<path
																	d="m501.749 38.52-28.285-28.285c-13.645-13.646-35.849-13.646-49.497 0l-226.273 226.274c-2.094 2.094-3.521 4.761-4.103 7.665l-14.143 70.711c-.983 4.918.556 10.002 4.103 13.548 2.841 2.841 6.668 4.394 10.606 4.394.979 0 1.963-.096 2.941-.291l70.711-14.143c2.904-.581 5.571-2.009 7.665-4.103l226.275-226.273s.001 0 .001-.001c13.645-13.645 13.645-35.849-.001-49.496zm-244.276 251.346-44.194 8.84 8.84-44.194 184.17-184.173 35.356 35.356zm223.063-223.062-17.678 17.678-35.356-35.356 17.677-17.677c1.95-1.95 5.122-1.951 7.072-.001l28.284 28.285c1.951 1.949 1.951 5.122.001 7.071z"
																	fill="currentColor"
																></path>
															</g>
														</svg>
														Edit
													</button>
												</PermissionAllowed>
											)}
										</>
									) : (
										<>
											<button
												type="button"
												className="btn btn-primary btn-outlined-main white-var"
												style={{ minWidth: 'unset' }}
												onClick={() => {
													openSendEmailModal(`${selectedEstimate?.master_status?.name == 'Credit Note' ? 'credit-note' : 'invoice'}`);
													setSelectedForSending(selectedEstimate?.id);
												}}
												disabled={isInternalLoading || !estimateRow?.estimate_number}
											>
												<svg
													id="fi_2983788"
													enable-background="new 0 0 512.005 512.005"
													height="18"
													viewBox="0 0 512.005 512.005"
													width="18"
													xmlns="http://www.w3.org/2000/svg"
												>
													<g>
														<path
															d="m511.658 51.675c2.496-11.619-8.895-21.416-20.007-17.176l-482 184c-5.801 2.215-9.638 7.775-9.65 13.984-.012 6.21 3.803 11.785 9.596 14.022l135.403 52.295v164.713c0 6.948 4.771 12.986 11.531 14.593 6.715 1.597 13.717-1.598 16.865-7.843l56.001-111.128 136.664 101.423c8.313 6.17 20.262 2.246 23.287-7.669 127.599-418.357 122.083-400.163 122.31-401.214zm-118.981 52.718-234.803 167.219-101.028-39.018zm-217.677 191.852 204.668-145.757c-176.114 185.79-166.916 176.011-167.684 177.045-1.141 1.535 1.985-4.448-36.984 72.882zm191.858 127.546-120.296-89.276 217.511-229.462z"
															fill="currentColor"
														></path>
													</g>
												</svg>
												Send
											</button>
											<button
												type="button"
												className="btn btn-primary btn-outlined-main white-var"
												style={{ minWidth: 'unset' }}
												disabled={isInternalLoading || !estimateRow?.estimate_number}
												onClick={() => {
													// if (selectedEstimate?.logistic?.name) {
													// 	downloadEstimate('invoice', 'logistic');
													// } else if (selectedEstimate?.transit_company?.name) {
													if (selectedEstimate?.transit_company?.name) {
														downloadEstimate('invoice', 'transitcompany');
													} else {
														downloadEstimate('invoice', 'customer');
													}
												}}
											>
												<svg
													id="fi_2767144"
													enable-background="new 0 0 128 128"
													height="18"
													viewBox="0 0 128 128"
													width="18"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														id="Download"
														d="m128 65c0 15.439-12.563 28-28 28h-20c-2.211 0-4-1.791-4-4s1.789-4 4-4h20c11.027 0 20-8.973 20-20s-8.973-20-20-20h-4c-2.211 0-4-1.791-4-4 0-15.439-12.563-28-28-28s-28 12.561-28 28c0 2.209-1.789 4-4 4h-4c-11.027 0-20 8.973-20 20s8.973 20 20 20h20c2.211 0 4 1.791 4 4s-1.789 4-4 4h-20c-15.437 0-28-12.561-28-28s12.563-28 28-28h.223c1.996-17.975 17.277-32 35.777-32s33.781 14.025 35.777 32h.223c15.438 0 28 12.561 28 28zm-50.828 37.172-9.172 9.172v-50.344c0-2.209-1.789-4-4-4s-4 1.791-4 4v50.344l-9.172-9.172c-1.563-1.563-4.094-1.563-5.656 0s-1.563 4.094 0 5.656l16 16c.781.781 1.805 1.172 2.828 1.172s2.047-.391 2.828-1.172l16-16c1.563-1.563 1.563-4.094 0-5.656s-4.094-1.563-5.656 0z"
														fill="currentColor"
													></path>
												</svg>
												Download
											</button>
											<button
												type="button"
												className="btn btn-primary btn-outlined-main white-var"
												style={{ minWidth: 'unset', display: 'none' }}
												disabled={isInternalLoading || !estimateRow?.estimate_number}
												onClick={() => {
													downloadEstimate('custom_invoice', 'customer');
												}}
											>
												<svg
													id="fi_2767144"
													enable-background="new 0 0 128 128"
													height="18"
													viewBox="0 0 128 128"
													width="18"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														id="Download"
														d="m128 65c0 15.439-12.563 28-28 28h-20c-2.211 0-4-1.791-4-4s1.789-4 4-4h20c11.027 0 20-8.973 20-20s-8.973-20-20-20h-4c-2.211 0-4-1.791-4-4 0-15.439-12.563-28-28-28s-28 12.561-28 28c0 2.209-1.789 4-4 4h-4c-11.027 0-20 8.973-20 20s8.973 20 20 20h20c2.211 0 4 1.791 4 4s-1.789 4-4 4h-20c-15.437 0-28-12.561-28-28s12.563-28 28-28h.223c1.996-17.975 17.277-32 35.777-32s33.781 14.025 35.777 32h.223c15.438 0 28 12.561 28 28zm-50.828 37.172-9.172 9.172v-50.344c0-2.209-1.789-4-4-4s-4 1.791-4 4v50.344l-9.172-9.172c-1.563-1.563-4.094-1.563-5.656 0s-1.563 4.094 0 5.656l16 16c.781.781 1.805 1.172 2.828 1.172s2.047-.391 2.828-1.172l16-16c1.563-1.563 1.563-4.094 0-5.656s-4.094-1.563-5.656 0z"
														fill="currentColor"
													></path>
												</svg>
												Download custom invoice to customer
											</button>
											{
												!statusAllowedToPayAndSalesOrder.includes(selectedEstimate?.master_status?.name) && (
													<>
														{
															(selectedEstimate?.paid_status != 'Paid' && !selectedEstimate?.sales_order_id ) && (
																<PermissionAllowed permissions={['Add Payment Transaction']}>
																	<button
																		type="button"
																		className="btn btn-primary btn-outlined-main white-var m-0"
																		style={{ minWidth: 'unset', margin: '0 !important' }}
																		onClick={() => {
																			setShowAddPaymentModal(true);
																		}}
																		disabled={isInternalLoading || !selectedEstimate?.estimation_number}
																	>
																		<RegisterPaymentIcon height="20px" width="20px" />
																		Payment
																	</button>
																</PermissionAllowed>
															)
														}
														{
															!selectedEstimate?.sales_order_id && (
																<>
																	<PermissionAllowed permissions={['Create Sales Order']}>
																		<button
																			type="button"
																			className="btn btn-primary btn-outlined-main white-var m-0"
																			style={{ minWidth: 'unset', margin: '0 !important' }}
																			onClick={() => createSalesOrder(selectedEstimate)}
																			disabled={isInternalLoading || !selectedEstimate?.estimation_number}
																		>
																			<SalesOrderIcon height="20px" width="20px" />
																			Create Sales Order 
																		</button>
																	</PermissionAllowed>
																</>
															)
														}
													</>
												)
											}
											{
												statusAllowedToEdit.includes(selectedEstimate?.master_status?.name) && (
													<PermissionAllowed permissions={['Edit Estimation (Button)']}>
														<button
															type="button"
															className="btn btn-primary btn-outlined-main white-var"
															style={{ minWidth: 'unset' }}
															disabled={isInternalLoading || !estimateRow?.estimate_number}
															onClick={() => {
																history(`${salesTypePath}/edit/${id}`);
															}}
														>
															<svg
																id="fi_2356780"
																style={{ marginTop: '-3px' }}
																enable-background="new 0 0 511.984 511.984"
																height="16"
																viewBox="0 0 511.984 511.984"
																width="16"
																xmlns="http://www.w3.org/2000/svg"
															>
																<g>
																	<path
																		d="m415 221.984c-8.284 0-15 6.716-15 15v220c0 13.785-11.215 25-25 25h-320c-13.785 0-25-11.215-25-25v-320c0-13.785 11.215-25 25-25h220c8.284 0 15-6.716 15-15s-6.716-15-15-15h-220c-30.327 0-55 24.673-55 55v320c0 30.327 24.673 55 55 55h320c30.327 0 55-24.673 55-55v-220c0-8.284-6.716-15-15-15z"
																		fill="currentColor"
																	></path>
																	<path
																		d="m501.749 38.52-28.285-28.285c-13.645-13.646-35.849-13.646-49.497 0l-226.273 226.274c-2.094 2.094-3.521 4.761-4.103 7.665l-14.143 70.711c-.983 4.918.556 10.002 4.103 13.548 2.841 2.841 6.668 4.394 10.606 4.394.979 0 1.963-.096 2.941-.291l70.711-14.143c2.904-.581 5.571-2.009 7.665-4.103l226.275-226.273s.001 0 .001-.001c13.645-13.645 13.645-35.849-.001-49.496zm-244.276 251.346-44.194 8.84 8.84-44.194 184.17-184.173 35.356 35.356zm223.063-223.062-17.678 17.678-35.356-35.356 17.677-17.677c1.95-1.95 5.122-1.951 7.072-.001l28.284 28.285c1.951 1.949 1.951 5.122.001 7.071z"
																		fill="currentColor"
																	></path>
																</g>
															</svg>
															Edit
														</button>
													</PermissionAllowed>
												)
											}
											{/* <Dropdown className="btnDel iconbtn">
														<Dropdown.Toggle as="span">
															<button
																type="button"
																className="btn btn-primary btn-outlined-main white-var"
																style={{ minWidth: 'unset' }}
																disabled={isInternalLoading || !estimateRow?.estimate_number}
															>
																<svg
																	id="fi_7268609"
																	enable-background="new 0 0 515.283 515.283"
																	height="15"
																	viewBox="0 0 515.283 515.283"
																	width="15"
																	xmlns="http://www.w3.org/2000/svg"
																>
																	<g>
																		<g>
																			<g>
																				<g>
																					<path
																						d="m400.775 515.283h-286.268c-30.584 0-59.339-11.911-80.968-33.54-21.628-21.626-33.539-50.382-33.539-80.968v-28.628c0-15.811 12.816-28.628 28.627-28.628s28.627 12.817 28.627 28.628v28.628c0 15.293 5.956 29.67 16.768 40.483 10.815 10.814 25.192 16.771 40.485 16.771h286.268c15.292 0 29.669-5.957 40.483-16.771 10.814-10.815 16.771-25.192 16.771-40.483v-28.628c0-15.811 12.816-28.628 28.626-28.628s28.628 12.817 28.628 28.628v28.628c0 30.584-11.911 59.338-33.54 80.968-21.629 21.629-50.384 33.54-80.968 33.54zm-143.134-114.509c-3.96 0-7.73-.804-11.16-2.257-3.2-1.352-6.207-3.316-8.838-5.885-.001-.001-.001-.002-.002-.002-.019-.018-.038-.037-.057-.056-.005-.004-.011-.011-.016-.016-.016-.014-.03-.029-.045-.044-.01-.01-.019-.018-.029-.029-.01-.01-.023-.023-.032-.031-.02-.02-.042-.042-.062-.062l-114.508-114.509c-11.179-11.179-11.179-29.305 0-40.485 11.179-11.179 29.306-11.18 40.485 0l65.638 65.638v-274.409c-.001-15.811 12.815-28.627 28.626-28.627s28.628 12.816 28.628 28.627v274.408l65.637-65.637c11.178-11.179 29.307-11.179 40.485 0 11.179 11.179 11.179 29.306 0 40.485l-114.508 114.507c-.02.02-.042.042-.062.062-.011.01-.023.023-.032.031-.01.011-.019.019-.029.029-.014.016-.03.03-.044.044-.005.005-.012.012-.017.016-.018.019-.037.038-.056.056-.001 0-.001.001-.002.002-.315.307-.634.605-.96.895-2.397 2.138-5.067 3.805-7.89 4.995-.01.004-.018.008-.028.012-.011.004-.02.01-.031.013-3.412 1.437-7.158 2.229-11.091 2.229z"
																						fill="currentColor"
																					></path>
																				</g>
																			</g>
																		</g>
																	</g>
																</svg>
																Download
															</button>
														</Dropdown.Toggle>

											{/* {
												(selectedEstimate?.master_status?.name != 'Credit Note') && (
													<>
														{
															selectedEstimate?.paid_status != 'Paid' && (
																<PermissionAllowed permissions={['RP Estimation (Button)']}>
																	<button
																		type="button"
																		className="btn btn-primary btn-outlined-main white-var m-0"
																		style={{ minWidth: 'unset', margin: '0 !important' }}
																		onClick={() => {
																			setShowAddPaymentModal(true);
																		}}
																		disabled={isInternalLoading || !estimateRow?.estimate_number}
																	>
																		<svg
																			id="fi_4748759"
																			height="22"
																			viewBox="0 0 128 128"
																			width="22"
																			xmlns="http://www.w3.org/2000/svg"
																			data-name="Слой 1"
																		>
																			<path
																				d="m74.9 39a2 2 0 0 0 2.46 1.4l9.93-2.72a2 2 0 0 0 1.4-2.45l-3.33-12.14a2 2 0 0 0 -2.45-1.4l-9.91 2.72a2 2 0 0 0 -1.4 2.46zm7.1-12.92 2.26 8.27-6.03 1.65-2.23-8.26z"
																				fill="currentColor"
																			></path>
																			<path
																				d="m119.79 64.39c-1.82-1.69-6.3-3.45-8.76-5.67a2.16 2.16 0 0 0 -.59-.37l-12.55-45.8a6.57 6.57 0 0 0 -8.08-4.61l-30.88 8.46a6.57 6.57 0 0 0 -4.61 8.08l11.49 41.92c-4.42 3.13-6.92 8-3.85 12.45a7.32 7.32 0 0 0 5.05 3 8.25 8.25 0 0 0 4 7.47c2.46 1.39 5 1 7.6-.24l-.3.76c-2.26 5.7.56 10.64 1.92 13 .17.3.38.67.45.83a4.5 4.5 0 0 1 -1.15 3.51 2 2 0 0 0 -.12 2.4c3.73 5.54 14.57 7.72 20.61 6.49 10.69-2.19 13.24-7.63 13.5-8.25a2.06 2.06 0 0 0 .07-1.32s-.79-3 0-8.38c.64-4.23 8.21-22.75 8.71-25.09a10.12 10.12 0 0 0 -2.51-8.64zm-61.61-41a2.57 2.57 0 0 1 1.82-3.13l30.87-8.46a2.57 2.57 0 0 1 3.13 1.8l13.3 48.54a2.57 2.57 0 0 1 -1.8 3.16l-10.42 2.87a30 30 0 0 0 -3.15-7.49c-3.39-5-10.3-8.51-14.58-4.58-3.08 2.83-1.81 6-.34 9.67a58.93 58.93 0 0 1 2.34 6.72c-1.66.47-4.06 1.13-5.15 1.36a2.31 2.31 0 0 1 -2.72-1.85zm44.62 51.4a26 26 0 0 1 -4 3 18.89 18.89 0 0 1 -2.54-5.79l2.47-.68a37.87 37.87 0 0 0 4.07 3.47zm-37.56 1.78c-1.44-2.09-.17-4.3 1.72-6 .5 1.24.58 4.8 3.83 6.58-2.59 1.38-4.64.73-5.55-.58zm14.91 6.84c-4.33 3.26-6.1 3-7.15 2.43a4.32 4.32 0 0 1 -1.93-4.45 15.62 15.62 0 0 0 6-4.12c1-.27 2.19-.59 3.15-.86a23.3 23.3 0 0 1 -.07 7zm38.28-11.2c-.41 1.92-8 20.53-8.75 25.34a28.92 28.92 0 0 0 -.11 9.18c-.79 1.12-3.43 4-10.23 5.42-9.28.89-13.94-2.19-15.72-3.83a8 8 0 0 0 1-5.61c-.46-1.94-4.59-6.2-2.53-11.4a29.57 29.57 0 0 0 1.68-17.83 59.27 59.27 0 0 0 -3-9.2c-1.52-3.79-1.59-4.38-.66-5.24 2.2-2 6.7 1.15 8.56 3.89 1.23 1.81 2.19 4.95 3.12 8 1.19 3.9 2.31 7.58 4.24 9.67 4.16 4.51 8.38 5.15 8.56 5.17a2 2 0 0 0 2.24-1.68 2 2 0 0 0 -1.67-2.28 9.29 9.29 0 0 1 -3.16-1.34 24.59 24.59 0 0 0 6.3-5.85 2 2 0 0 0 -2.78-2.78c-.67-.51-1.48-1.16-2.23-1.78l3.29-.9a6.56 6.56 0 0 0 4.75-5.3c2.33 1.44 4.91 2.69 5.74 3.46a6.3 6.3 0 0 1 1.36 4.89z"
																				fill="currentColor"
																			></path>
																			<path
																				d="m26.63 71.1a2 2 0 0 0 2.72-.78c5.11-9.21 5.11-17.43 0-26.64a2 2 0 0 0 -3.5 1.94c4.43 8 4.43 14.78 0 22.76a2 2 0 0 0 .78 2.72z"
																				fill="currentColor"
																			></path>
																			<path
																				d="m19 66.31a2 2 0 0 0 2.74-.68 16.5 16.5 0 0 0 0-17.26 2 2 0 0 0 -3.42 2.06 12.62 12.62 0 0 1 0 13.14 2 2 0 0 0 .68 2.74z"
																				fill="currentColor"
																			></path>
																			<path
																				d="m35.25 74.94a2 2 0 0 0 2.69-.9c6-11.94 6-22.14 0-34.08a2 2 0 1 0 -3.58 1.79c5.41 10.83 5.41 19.67 0 30.5a2 2 0 0 0 .89 2.69z"
																				fill="currentColor"
																			></path>
																			<path
																				d="m43.8 78.73a2 2 0 0 0 2.68-.89c6.92-13.84 6.92-27.84 0-41.68a2 2 0 1 0 -3.57 1.84c6.4 12.82 6.4 25.28 0 38.1a2 2 0 0 0 .89 2.63z"
																				fill="currentColor"
																			></path>
																			<path
																				d="m58 97a92.84 92.84 0 0 0 11.2-.65 2 2 0 1 0 -.5-4 88.65 88.65 0 0 1 -10.7.65c-27.58 0-50-16.15-50-36 0-16.12 15.07-30.39 36.64-34.7 1-.21 3.3-.73 4.32-.87a2 2 0 1 0 -.55-4c-1.22.17-3.41.68-4.55.91-23.47 4.72-39.86 20.66-39.86 38.66 0 22.06 24.21 40 54 40z"
																				fill="currentColor"
																			></path>
																		</svg>
																		Payment
																	</button>
																</PermissionAllowed>
															)
														}
													</>
												)
											} */}
											{
												!statusAllowedToDeleteDraft.includes(selectedEstimate?.master_status?.name) &&
												(
													<>
														{
															!selectedEstimate?.is_processed && (
																<>
																	<PermissionAllowed permissions={['Add to Purchase List (Action)']}>
{/* 			
																		<button
																			type="button"
																			className="btn btn-primary btn-outlined-main white-var m-0"
																			style={{ minWidth: 'unset', margin: '0 !important' }}
																			onClick={() => {
																				addToPurchaseList(selectedEstimate.id);
																			}}
																			disabled={isInternalLoading || !estimateRow?.estimate_number}
			
																		>
																			<GoChecklist style={{ fontSize: '17px' }} />
																			Process Order
																		</button> */}
			
																		{/* <button
																			type="button"
																			className="btn btn-primary btn-outlined-main white-var m-0"
																			style={{ minWidth: 'unset', margin: '0 !important' }}
																			onClick={() => {
																				// addToPurchaseList(selectedEstimate.id);
																				addPurchaseOrder(selectedEstimate.id);
																			}}
																			disabled={isInternalLoading || !estimateRow?.estimate_number}
			
																		>
																			<GoChecklist style={{ fontSize: '17px' }} />
																			Purchase Order
																		</button> */}
																	</PermissionAllowed>	
																</>
															)
														}
													</>
												)
											}
											{/* // )} */}
											{/* <Dropdown className="btnDel iconbtn" align={'end'}>
												<Dropdown.Toggle as="span">
													<Link to={''} className="btn btn-primary btn-outlined-main white-var" style={{ minWidth: 'unset' }}>
														Extra <LuChevronDown />
													</Link>
												</Dropdown.Toggle>
												<Dropdown.Menu>

													{
														selectedEstimate?.master_status?.status_type_id != 2 && (
															<>
																{
																	selectedEstimate?.master_status?.name != 'Credit Note' && (
																		<PermissionAllowed permissions={['Edit Estimation (Button)']}>
																			<Dropdown.Item
																				onClick={() => {
																					openAWBModal();
																				}}
																			>
																				Add AWB
																			</Dropdown.Item>
																		</PermissionAllowed>
																	)
																}
															</>
														)
													}
													{selectedEstimate?.master_status?.name == 'Unprocessed' && (
														<PermissionAllowed permissions={['Add to Purchase List (Action)']}>
															<Dropdown.Item
																onClick={() => {
																	addToPurchaseList(selectedEstimate.id);
																}}
															>
																Process Order
															</Dropdown.Item>
														</PermissionAllowed>
													)}

													<PermissionAllowed permissions={['Delete Estimation (Button)']}>
														<Dropdown.Item
															onClick={() => {
																deleteEstimate(selectedEstimate);
															}}
														>
															Delete
														</Dropdown.Item>
													</PermissionAllowed>
												</Dropdown.Menu>
											</Dropdown> */}
										</>
									)}
								</>
							)}
						</div>
						<div className="actions" style={{ gap: '5px' }}>

							<PermissionAllowed permissions={['Delete Estimation (Button)']}>
							{
								statusAllowedToDeleteDraft.includes(selectedEstimate?.master_status?.name) && (
									<>
										{
											((selectedEstimate?.master_status?.name == 'Draft' && selectedEstimate?.master_status?.status_type_id == 2) ||
												(selectedEstimate?.master_status?.name == 'Draft' && selectedEstimate?.master_status?.status_type_id == 3 && !selectedEstimate?.qoutation_id)
											) && (
												<>

													<button
														type="button"
														className="btn btn-primary btn-outlined-danger white-var"
														style={{ minWidth: 'unset' }}
														disabled={isInternalLoading || !estimateRow?.estimate_number}
														onClick={() => {
															deleteEstimate(selectedEstimate);
														}}
													>

														<FaRegTrashCan />
														Delete Draft
													</button>
												</>
											)
										}
									</>
								)
							}
							</PermissionAllowed>
							{

								(selectedEstimate?.master_status?.name) && (
									<>
										{
											(
												// selectedEstimate?.master_status?.name == 'Unprocessed' ||
												statusAllowedToAccept.includes(selectedEstimate?.master_status?.name) ||
												statusAllowedToCancel.includes(selectedEstimate?.master_status?.name) 
												// statusAllowedToApprove.includes(selectedEstimate?.master_status?.name)
											)
											&& (
												<>

													<PermissionAllowed permissions={['Edit Estimation (Button)']}>
														<Dropdown className="btnDel est-btn iconbtn" align={'end'}>
															<Dropdown.Toggle as="span">
																<Link to={''} className="btn btn-primary btn-outlined-main white-var" style={{ minWidth: 'unset' }}>
																	Change status <LuChevronDown />
																</Link>
															</Dropdown.Toggle>
															<Dropdown.Menu>

																{/* {
																selectedEstimate?.master_status?.status_type_id != 2 && (
																	<>
																		{
																			selectedEstimate?.master_status?.name != 'Credit Note' && (
																				<PermissionAllowed permissions={['Edit Estimation (Button)']}>
																					<Dropdown.Item
																						onClick={() => {
																							openAWBModal();
																						}}
																					>
																						Add AWB
																					</Dropdown.Item>
																				</PermissionAllowed>
																			)
																		}
																	</>
																)
															} */}
																{/* {selectedEstimate?.master_status?.name == 'Unprocessed' && (
																	<PermissionAllowed permissions={['Add to Purchase List (Action)']}>
																		<Dropdown.Item
																			onClick={() => {
																				addToPurchaseList(selectedEstimate.id);
																			}}
																		>
																			Process Order
																		</Dropdown.Item>
																	</PermissionAllowed>
																)} */}
																{/* <Dropdown.Item
																						onClick={() => {
																							history(`${salesTypePath}/edit/${id}`);
																						}}
																					>
																						Duplicate Estimate
																					</Dropdown.Item> */}

														{statusAllowedToAccept.includes(selectedEstimate?.master_status?.name) && (
															<PermissionAllowed permissions={['Accept Estimation (Button)']}>
																<Dropdown.Item onClick={() => acceptOrder(selectedEstimate)}>Accept</Dropdown.Item>
															</PermissionAllowed>
														)}
														{statusAllowedToAccept.includes(selectedEstimate?.master_status?.name) && (
															<PermissionAllowed permissions={['Create New Estimation (Action)']}>
																<Dropdown.Item onClick={() => billOrder(selectedEstimate)}>Billed</Dropdown.Item>
															</PermissionAllowed>
														)}
														{statusAllowedToCancel.includes(selectedEstimate?.master_status?.name) && (
															<PermissionAllowed permissions={['Cancel Estimation (Button)']}>
																<Dropdown.Item onClick={() => { setShowCancelPopup(true) }} >
																	Cancel
																</Dropdown.Item>
															</PermissionAllowed>
														)}

														{/* {statusAllowedToApprove.includes(selectedEstimate?.master_status?.name) && (
															<PermissionAllowed permissions={['Approve Estimation (Button)']}>
																<Dropdown.Item onClick={() => approveOrder(selectedEstimate)}>Billed</Dropdown.Item>
															</PermissionAllowed>
														)} */}
													</Dropdown.Menu>
												</Dropdown>
											</PermissionAllowed>
										</>
									)}
								</>
							)}

							<div className="est-detail-item">
								{selectedEstimate?.master_status?.name == 'Credit Note' ? (
									<>
										{selectedEstimate?.paid_status == 'Paid' ? (
											<>
												<StatusBadge itemStatus="Paid" />
											</>
										) : (
											<>
												<StatusBadge itemStatus={selectedEstimate?.is_draft == 1 ? 'Draft' : selectedEstimate?.master_status?.name} />
											</>
										)}
									</>
								) : (
									<>
										<StatusBadge itemStatus={selectedEstimate?.is_draft == 1 ? 'Draft' : selectedEstimate?.master_status?.name} />
									</>
								)}
							</div>
						</div>
					</div>
					<div className="row m-0">
						<div className="card shadow-sm rounded bg-white mb-3 p-3 p-xl-4 w-100">
							<EstimateDetailInvoice estimate={selectedEstimate} />
							
							{
								salesType === 'estimate' && (
									<>
										{
											selectedEstimate?.invoice?.id && (
												<>
													<InvoiceComponent details={selectedEstimate} />
												</>
											)
										}
									</>
								)
							}
							{
								salesType === 'invoice' && (
									<>
									{
										selectedEstimate?.qoutation?.id && (
											<>
												<QoutationComponent details={selectedEstimate} />
											</>
										)
									}
									</>
								)
							}
							{
								selectedEstimate?.sales_order?.id && (
									<>
										<SalesOrderComponent details={selectedEstimate} />
									</>
								)
							}

							{showAcceptPopup && (
								<AcceptOrderModal show={showAcceptPopup} handleClose={() => setShowAcceptPopup(null)} handleConfirm={handleAcceptConfirm} />
							)}
						</div>
						{salesType != 'credit-note' && (
							<>
								{orderAgreementsData?.length > 0 && (
									<>
										<div className="card shadow-sm rounded bg-white mb-3 p-3 p-xl-4 w-100">
											<div className="row">
												<div className="col-12">
													<div className="row">
														<div className="col-12">
															<div className="text-center mb-3 pt-2">
																<img src={agreementImage} alt="" height="70px" className="agreement-img" />
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-12">
															<h4 className="text-center mb-3">SALES AGREEMENT</h4>
														</div>
													</div>
													<div className="row">
														<div className="col-md-10 offset-md-1">
															<p className="text-center mb-3">
																This agreement is made on <Link to="#">{moment(selectedEstimate?.invoiceDate).format('DD MMMM YYYY')}</Link>{' '}
																between{' '}
																<Link to={`/contacts/${selectedEstimate?.customer?.id}`} target="_blank">
																	<PrivacyModeContent>{selectedEstimate?.customer?.name}</PrivacyModeContent>
																</Link>
																,{' '}
																{selectedEstimate?.customer?.address && (
																	<>
																		located at{' '}
																		<Link to="#">
																			<PrivacyModeContent>
																				{selectedEstimate?.customer?.address ? `${selectedEstimate?.customer?.address}, ` : ''}
																				{selectedEstimate?.customer?.zipcode ? `${selectedEstimate?.customer?.zipcode}, ` : ''}
																				{selectedEstimate?.customer?.city ? `${selectedEstimate?.customer?.city}, ` : ''}
																				{selectedEstimate?.customer?.country ? `${selectedEstimate?.customer?.country}` : ''}
																			</PrivacyModeContent>
																		</Link>{' '}
																		(The Client){' '}
																	</>
																)}
																and{' '}
																{selectedEstimate?.transit_company?.name && (
																	<>
																		<Link to={`/contacts/${selectedEstimate?.transit_company?.id}`} target="_blank">
																			{selectedEstimate?.transit_company?.name}
																		</Link>{' '}
																		{selectedEstimate?.transit_company?.address && (
																			<>
																				located at{' '}
																				<Link to="#">
																					{selectedEstimate?.transit_company?.address
																						? `${selectedEstimate?.transit_company?.address}, `
																						: ''}
																					{selectedEstimate?.transit_company?.zipcode
																						? `${selectedEstimate?.transit_company?.zipcode}, `
																						: ''}
																					{selectedEstimate?.transit_company?.city ? `${selectedEstimate?.transit_company?.city}, ` : ''}
																					{selectedEstimate?.transit_company?.country
																						? `${selectedEstimate?.transit_company?.country}`
																						: ''}
																				</Link>{' '}
																			</>
																		)}
																		(The Supplier).
																	</>
																)}
															</p>
														</div>
													</div>

													<div className="row">
														<div className="col-md-10 offset-md-1">
															<p className="text-center mb-3">
																Both parties confirm that they have mutually agreed upon the following conditions, as negotiated between{' '}
																<b>
																	{selectedEstimate?.sales_person?.first_name} {selectedEstimate?.sales_person?.last_name}
																</b>
																, {selectedEstimate?.transit_company?.name && <> representing {selectedEstimate?.transit_company?.name}</>},{' '}
																and{' '}
																{selectedEstimate?.customer?.contact_name ? (
																	<>
																		<Link to={`contact/${selectedEstimate?.customer?.id}`} target="_blank">
																			<PrivacyModeContent>{selectedEstimate?.customer?.name}</PrivacyModeContent>
																		</Link>
																		, the representative of{' '}
																	</>
																) : (
																	<></>
																)}{' '}
																the representative of{' '}
																<Link to={`/contacts/${selectedEstimate?.customer?.id}`} target="_blank">
																	<PrivacyModeContent>{selectedEstimate?.customer?.name}</PrivacyModeContent>
																</Link>
																.
															</p>
														</div>
													</div>

													<div className="order-agreements-detail-section">
														{selectedEstimate?.order_agreements?.length > 0 && (
															<>
																{selectedEstimate?.order_agreements?.map((ag) => (
																	<div className="order-agreements-detail">
																		<h6>{ag?.title}</h6>
																		{ag?.type == 'multiselect' ? (
																			ag?.responses?.map((res) => <p>{res?.option?.option}</p>)
																		) : (
																			<p>{ag?.response?.label}</p>
																		)}
																	</div>
																))}
																<div className="row">
																	<div className="col-12">
																		<p
																			className="d-flex justify-content-center align-items-center text-center mb-4 pt-4"
																			style={{ gap: '10px', color: '#838383', fontStyle: 'italic' }}
																		>
																			Note: <br />
																			Please be aware that a 2% charge will be applied to the entire order amount if payment is not made on
																			time,
																			<br />
																			due to fluctuations in USD and EUR currency values.
																		</p>
																	</div>
																</div>
															</>
														)}
													</div>
												</div>
											</div>
										</div>
									</>
								)}
							</>
						)}
						<div className="row" style={{ marginBottom: 10 }}>
							<div className="col-12 d-flex">
								{selectedEstimate?.payments?.length > 0 && (
									<>
										<div>
											<div
												className={`estimates-payment-shipment ${selectedPaymentShipment === 'payment' ? 'active' : ''}`}
												style={{ mariginLeft: 5 }}
												onClick={() => handleSelectMenu('payment')}
											>
												<svg
													id="fi_1989125"
													enable-background="new 0 0 124 124"
													height="20"
													viewBox="0 0 124 124"
													width="20"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fill="currentColor"
														d="m118.052 26.953-88.813-15.842c-3.892-.7-7.673 2.023-8.348 5.894l-5.72 32.814h-8.171c-3.86 0-7 3.141-7 7v49.18c0 3.859 3.14 7 7 7h90.587c3.86 0 7-3.141 7-7v-17.293c2.595.463 5.1 1.009 7.499-.426 1.8-1.077 3.091-2.934 3.452-5.003l8.352-47.916c.687-3.941-1.932-7.713-5.838-8.408zm-93.22-9.261c.3-1.719 1.968-2.954 3.705-2.644l88.813 15.842c1.743.311 2.909 2.008 2.6 3.783l-1.378 7.91-95.122-16.966zm75.755 88.307c0 1.654-1.346 3-3 3h-90.587c-1.654 0-3-1.346-3-3v-49.18c0-1.654 1.346-3 3-3h90.587c1.654 0 3 1.346 3 3zm11.011-23.409c-.622 3.557-4.572 2.488-7.011 2.053v-27.824c0-3.859-3.14-7-7-7h-78.355l3.531-20.262 5.794 1.033 89.327 15.933z"
													></path>
													<path
														fill="currentColor"
														d="m47.119 101.003h-11.5c-1.104 0-2-.896-2-2s.896-2 2-2h11.5c1.104 0 2 .896 2 2s-.895 2-2 2z"
													></path>
													<path
														fill="currentColor"
														d="m25.271 101.003h-11.5c-1.104 0-2-.896-2-2s.896-2 2-2h11.5c1.104 0 2 .896 2 2s-.896 2-2 2z"
													></path>
													<path
														fill="currentColor"
														d="m68.967 101.003h-11.5c-1.104 0-2-.896-2-2s.896-2 2-2h11.5c1.104 0 2 .896 2 2s-.895 2-2 2z"
													></path>
													<path
														fill="currentColor"
														d="m90.816 101.003h-11.5c-1.104 0-2-.896-2-2s.896-2 2-2h11.5c1.104 0 2 .896 2 2s-.896 2-2 2z"
													></path>
													<path
														fill="currentColor"
														d="m30.438 90.627h-14.486c-2.757 0-5-2.243-5-5v-5.744c0-2.757 2.243-5 5-5h14.486c2.757 0 5 2.243 5 5v5.744c0 2.756-2.244 5-5 5zm-14.486-11.745c-.551 0-1 .448-1 1v5.744c0 .552.449 1 1 1h14.486c.551 0 1-.448 1-1v-5.744c0-.552-.449-1-1-1z"
													></path>
												</svg>
												<span style={{ marginLeft: 5 }}>Payments</span>
											</div>
											<div
												style={{
													marginTop: '0px',
													marginLeft: '8px',
													width: '85%',
													height: '2px',
													borderRadius: '2px',
													backgroundColor: selectedPaymentShipment === 'payment' ? '#0066FF' : 'transparent'
												}}
											></div>
										</div>
										<div>
											{selectedEstimate?.shipmentDetails?.length > 0 && (
												<>
													<div
														className={`estimates-payment-shipment ${selectedPaymentShipment === 'shipment' ? 'active' : ''}`}
														onClick={() => handleSelectMenu('shipment')}
													>
														<svg id="fi_5669850" height="20" viewBox="0 0 64 64" width="20" xmlns="http://www.w3.org/2000/svg">
															<g>
																<g>
																	<path
																		d="m9.1 17.4c-.9 0-1.7-.4-2.2-1l-.8-.8c-.6-.6-1-1.5-1-2.4 0-.8.4-1.6 1-2.1l1-1c.3-.3.7-.4 1-.2l5.7 2.1c.3.1.5.4.6.7s0 .7-.3.9l-2.8 2.8c-.5.7-1.3 1-2.2 1zm-1.1-5.4-.6.6c-.3.2-.4.5-.4.8s.1.7.4.9l.8.8c.5.6 1.2.5 1.7 0l1.7-1.7z"
																		fill="currentColor"
																	></path>
																</g>
																<g>
																	<path
																		d="m28.4 28.2c-.3 0-.5-.1-.7-.3l-11.2-12.7-12.2-4.6c-1-.4-1.7-1.2-2-2.2l-1.1-3.5c-.3-1.1-.1-2.2.5-3.1.8-1 2.1-1.5 3.4-1.2l37.8 11.5c.3.1.6.4.7.7s0 .7-.3.9l-14.2 14.2c-.2.2-.4.3-.7.3zm-24-25.8c-.5 0-.8.2-1.1.6s-.4.9-.3 1.3l1.1 3.5c.1.5.4.8.9 1l12.5 4.7c.1.1.3.1.4.3l10.6 12 12.3-12.3-36.2-11.1s-.1 0-.2 0z"
																		fill="currentColor"
																	></path>
																</g>
																<g>
																	<path
																		d="m17 15.4c-.3 0-.6-.1-.8-.4-.3-.4-.2-1 .2-1.4l5-3.7c.4-.3 1-.2 1.4.2.3.4.2 1-.2 1.4l-5 3.7c-.2.1-.4.2-.6.2z"
																		fill="currentColor"
																	></path>
																</g>
																<g>
																	<path
																		d="m12.6 41.3c-.1 0-.2 0-.3-.1l-5.9-2.1c-.9-.3-1.6-1.1-1.8-2.1s0-2 .7-2.8l1.4-1.4c.6-.6 1.3-.9 2.1-.9l8.5.2c.5 0 1 .5.9 1 0 .5-.4.9-1 .9l-8.5-.2c-.2 0-.5.1-.7.3l-1.4 1.4c-.2.3-.3.6-.2 1 .1.3.3.6.6.7l5.9 2.1c.5.2.8.7.6 1.2-.1.5-.5.8-.9.8z"
																		fill="currentColor"
																	></path>
																</g>
																<g>
																	<path
																		d="m38.8 38.3c-.2 0-.5-.1-.6-.2l-3-2.6c-.4-.4-.4-1-.1-1.4.4-.4 1-.4 1.4-.1l3 2.6c.4.4.4 1 .1 1.4-.3.2-.5.3-.8.3z"
																		fill="currentColor"
																	></path>
																</g>
																<g>
																	<path
																		d="m55.1 38.2c-.4 0-.8-.3-.9-.7l-5.1-16.8c-.2-.5.1-1.1.6-1.2.5-.2 1.1.1 1.2.6l5.1 16.8c.2.5-.1 1.1-.6 1.2-.1.1-.2.1-.3.1z"
																		fill="currentColor"
																	></path>
																</g>
																<g>
																	<path
																		d="m26.7 58.5c-.2 0-.4 0-.6-.1-1-.2-1.8-.9-2.1-1.8l-2.1-5.9c-.2-.5.1-1.1.6-1.2.5-.2 1.1.1 1.2.6l2.1 5.9c.1.3.4.5.7.6.2 0 .6.1.9-.3l1.3-1.5c0-.1.1-.1.2-.1.1-.1.2-.3.2-.6l-.2-8.5c0-.5.4-1 .9-1s1 .4 1 .9l.2 8.5c0 .7-.2 1.6-.9 2.1l-1.2 1.4c-.7.7-1.4 1-2.2 1z"
																		fill="currentColor"
																	></path>
																</g>
																<g>
																	<path
																		d="m14.9 50.4c-.7 0-1.2-.2-1.6-.6-.9-.9-1.4-2.7 2.5-9 2-3.4 4.7-6.9 5.8-8.1l28.6-28.7c1.6-1.6 6-4 9.1-4 1.2 0 2.1.3 2.7 1 1.2 1.2 1.3 3.4.2 6.2-.8 2.3-2 4.5-3.3 5.6l-28.6 28.7c-1.7 1.7-11.5 8.9-15.4 8.9zm-.3-2h.3c3 0 12.1-6.4 14-8.3l28.7-28.7c1-.9 2.1-2.9 2.8-4.8s.8-3.6.2-4.2c-.3-.4-.9-.4-1.3-.4-2.5 0-6.3 2-7.7 3.4l-28.7 28.7c-2.8 2.8-9.1 12.8-8.3 14.3z"
																		fill="currentColor"
																	></path>
																</g>
																<g>
																	<path
																		d="m57.5 9.3c-.2 0-.4 0-.5-.1-.5-.3-.6-.9-.3-1.3s.1-.9-.2-1.2-.8-.4-1.2-.2c-.5.3-1.1.1-1.3-.3-.3-.5-.1-1.1.3-1.3 1.1-.7 2.6-.5 3.6.4 1 1 1.1 2.5.4 3.6-.2.2-.5.4-.8.4z"
																		fill="currentColor"
																	></path>
																</g>
																<g>
																	<path
																		d="m11.3 52.8c-.2 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l10.6-10.6c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-10.6 10.6c-.2.2-.5.3-.7.3z"
																		fill="currentColor"
																	></path>
																</g>
																<g>
																	<path
																		d="m58.2 64h-18.5c-2.7 0-4.9-2.2-4.9-4.9v-14.1c0-2.7 2.2-4.9 4.9-4.9h18.5c2.7 0 4.9 2.2 4.9 4.9v14.2c-.2 2.7-2.3 4.8-4.9 4.8zm-18.5-21.9c-1.6 0-2.9 1.3-2.9 2.9v14.1c0 1.6 1.3 2.9 2.9 2.9h18.5c1.6 0 2.9-1.3 2.9-2.9v-14.2c0-1.6-1.3-2.9-2.9-2.9h-18.5z"
																		fill="currentColor"
																	></path>
																</g>
																<g>
																	<path
																		d="m52.3 50.1c-.2 0-.5-.1-.6-.2l-2.8-2.4-2.7 2.3c-.3.3-.7.3-1 .2-.3-.2-.6-.5-.6-.9v-5.1c0-.5.4-1 1-1s1 .4 1 1v3l1.7-1.5c.4-.3.9-.3 1.3 0l1.8 1.5v-3c0-.5.4-1 1-1s1 .4 1 1v5.1c0 .4-.2.7-.6.9-.2 0-.4.1-.5.1z"
																		fill="currentColor"
																	></path>
																</g>
																<g>
																	<g>
																		<path
																			fill="currentColor"
																			d="m58.1 57.1h-4.7c-.5 0-1-.4-1-1s.4-1 1-1h4.7c.5 0 1 .4 1 1s-.5 1-1 1z"
																		></path>
																	</g>
																	<g>
																		<path
																			fill="currentColor"
																			d="m58.1 60.1h-4.7c-.5 0-1-.4-1-1s.4-1 1-1h4.7c.5 0 1 .4 1 1s-.5 1-1 1z"
																		></path>
																	</g>
																</g>
															</g>
														</svg>
														<span style={{ marginLeft: 5 }}>Shipments</span>
													</div>
													<div
														style={{
															marginTop: '0px',
															marginLeft: '8px',
															width: '85%',
															height: '2px',
															borderRadius: '2px',
															backgroundColor: selectedPaymentShipment === 'shipment' ? '#0066FF' : 'transparent'
														}}
													></div>
												</>
											)}
										</div>
									</>
								)}
							</div>
						</div>
						{selectedPaymentShipment === 'payment' && (
							<div
								className="card shadow-sm rounded bg-white mb-3 p-1 w-100"
								style={{ display: paymentData?.length > 0 ? 'block' : 'none' }}
							>
								<PaymentsComponent
									paymentData={paymentData}
									setPaymentData={setPaymentData}
									estimateDetails={selectedEstimate}
									onAddPayment={handleAddPayment}
									onUpdatePayment={handleUpdatePayment}
									onDeletePayment={handleDeletePayment}
									setShowAddPayment={setShowAddPaymentModal}
									showAddPayment={showAddPaymentModal}
									toggleExpand={handlePaymentToggle}
								/>
							</div>
						)}
						{selectedPaymentShipment === 'shipment' && (
							<div
								className="card shadow-sm rounded bg-white mb-3 p-1 w-100"
								style={{ display: shipmentData?.length > 0 ? 'block' : 'none' }}
							>
								<ShipmentsComponent shipmentData={shipmentData} setShipmentData={setShipmentData} estimateDetails={selectedEstimate} />
							</div>
						)}
					</div>
				</div>
				<div className="col-12 col-md-4 inv-details">
					<div className="marginTop65">
						{/* <div className="est-detail-item">
							<label htmlFor="inputName" className="est-labels">
								Status
							</label>

							<p>{selectedEstimate?.master_status?.name}</p>
						</div> */}
						<div className="est-detail-item">
							<label htmlFor="inputName" className="est-labels">
								Currency & Rate
							</label>

							<p>
								{selectedEstimate?.currency?.toUpperCase()}, {/* {getCurrencySymbol(selectedEstimate?.currency)}  */}1 ={' '}
								{selectedEstimate?.currency == 'eur'
									? `$ ${(
											(1 / selectedEstimate?.currency_rate?.currency_rates[1]?.rate) *
											selectedEstimate?.currency_rate?.currency_rates[2]?.rate
									  ).toFixed(4)}`
									: selectedEstimate?.currency == 'usd'
									? `€ ${(
											(1 / selectedEstimate?.currency_rate?.currency_rates[2]?.rate) *
											selectedEstimate?.currency_rate?.currency_rates[1]?.rate
									  ).toFixed(4)}`
									: selectedEstimate?.currency == 'aed'
									? `€ ${(
											(1 / selectedEstimate?.currency_rate?.currency_rates[0]?.rate) *
											selectedEstimate?.currency_rate?.currency_rates[1]?.rate
									  ).toFixed(4)}`
									: ''}
							</p>
						</div>
						{selectedEstimate?.master_status?.status_type_id == 2 ? (
							<>
								{selectedEstimate?.transit_company?.name ? (
									<>
										<div className="est-detail-item">
											<label htmlFor="inputName" className="est-labels">
												Transit Company
											</label>

											<Link to={`/contacts/${selectedEstimate?.transit_company?.id}`}>
												<PrivacyModeContent notUserRestricted>{selectedEstimate?.transit_company?.name}</PrivacyModeContent>
											</Link>
										</div>
									</>
								) : (
									<>
										<div className="est-detail-item">
											<label htmlFor="inputName" className="est-labels">
												Customer
											</label>

											<Link to={`/contacts/${selectedEstimate?.customer?.id}`}>
												<PrivacyModeContent notUserRestricted>{selectedEstimate?.customer?.name}</PrivacyModeContent>
											</Link>
										</div>
									</>
								)}
							</>
						) : (
							<>
								{selectedEstimate?.transit_company?.name ? (
									<>
										<div className="est-detail-item">
											<label htmlFor="inputName" className="est-labels">
												Transit Company
											</label>

											<Link to={`/contacts/${selectedEstimate?.transit_company?.id}`}>
												<PrivacyModeContent>{selectedEstimate?.transit_company?.name}</PrivacyModeContent>
											</Link>
										</div>
									</>
								) : (
									<>
										<div className="est-detail-item">
											<label htmlFor="inputName" className="est-labels">
												Customer
											</label>

											<Link to={`/contacts/${selectedEstimate?.customer?.id}`}>
												<PrivacyModeContent>{selectedEstimate?.customer?.name}</PrivacyModeContent>
											</Link>
										</div>
									</>
								)}
								{/* </>
										)
									} */}
							</>
						)}

						{
							selectedEstimate?.master_status?.name != 'Credit Note' && (
								<div className="est-detail-item">
									<label htmlFor="inputName" className="est-labels">
										Sales Manager
									</label>

									<Link to={`/settings/user/${selectedEstimate?.sales_person?.id}`}>
										{selectedEstimate?.sales_person?.first_name} {selectedEstimate?.sales_person?.last_name}
									</Link>
								</div>
							)
						}
						{
							(
								selectedEstimate?.is_draft != 1
								&& selectedEstimate?.paid_status != 'Paid'
								&& selectedEstimate?.master_status?.name != 'Accepted'
								&& selectedEstimate?.master_status?.name != 'Invoiced'
							) && (
								<>
									{selectedEstimate?.master_status?.name != 'Invoiced' && selectedEstimate?.master_status?.name != 'Paid' && (
										<div className="mt20">
											<TimelineStep
												selectedEstimate={selectedEstimate}
												startDateParam={selectedEstimate?.transaction_type == 'Invoice' ? selectedEstimate?.invoice_date : selectedEstimate?.start_date}
												endDateParam={selectedEstimate?.transaction_type == 'Invoice' ? selectedEstimate?.invoice_due_date : selectedEstimate?.expires_at}
											/>
										</div>
									)}
								</>
							)
						}
						<Notes
							notes={estimateRow?.notes}
							addNote={addNote}
							deleteNotePermission={[]}
							removeNote={removeNote}
							toggleNoteExpand={toggleNoteExpand}
							parentId={1}
							type="quotation"
						/>
					</div>
				</div>
				{confirmationDialog?.show && (
					<ActionConfirmationModal
						show={confirmationDialog?.show}
						message={confirmationDialog?.message}
						title={confirmationDialog?.title}
						customConfirmText={confirmationDialog?.customConfirm || 'Confirm'}
						customCancelText={confirmationDialog?.customCancel || 'Cancel'}
						customIcon={<PiInfoFill />}
						handleConfirm={confirmationDialog?.handleConfirm}
						handleClose={confirmationDialog?.handleClose}
					/>
				)}

				{openSendEmailPopup && (
					<SendEmailModal
						openPopup={openSendEmailPopup}
						selectedEstimate={selectedEstimate}
						handleClose={closeSendEmailPopup}
						sendEstimateEmail={handleSendEstimateEmail}
						sendingEstimate={sendingEstimate}
						selectedForSending={selectedForSending}
						disableStatus={isDisable}
					/>
				)}

				{showDeletePopup && (
					<DeleteConfirmation
						show={showDeletePopup}
						handleClose={handleCloseDeletePopup}
						handleDelete={handleDelete}
						title="Confirm"
						message={`${deleteMessage}`}
					/>
				)}

				{/* {showAWBModal && <AWBModal handleClose={() => closeAWBModal()} selectedEstimate={selectedEstimate} />} */}

				<AddToPurchaseListConfirmation
					show={addToPl}
					handleClose={handlePlDialogClose}
					handleConfirm={(showId, items) => handleAddToPurchaseList(showId, items)}
				/>


				{showCancelPopup && (
					<DeleteConfirmation
						show={showCancelPopup}
						handleClose={handleClose}
						handleDelete={handleCancel}
						title="Are you sure?"
						message={`By canceling, this ${salesType} cannot be rolled back. Do you want to proceed?`}
						customDeleteButtonText={`Yes, Cancel this ${ salesType === 'estimate' ? 'Quotation' : (salesType === 'invoice' ? 'Invoice' : 'Credit Note') }`}
					/>
				)}


				{showPurchaseOrderModal && (
					<PurchaseOrderModal
						openPopup={showPurchaseOrderModal}
						selectedEstimate={selectedEstimate}
						handleClose={closePurchaseOrderPopup}
						handleItems={purchaseOrderItems}
						handleCreatePurchaseOrder={handleCreatePurchaseOrder}
					// sendEstimateEmail={handleSendEstimateEmail}
					// sendingEstimate={sendingEstimate}
					// selectedForSending={selectedForSending}
					/>
				)}
				{/* {showAcceptPopup && (
				<AcceptOrderModal show={showAcceptPopup} handleClose={() => setShowAcceptPopup(null)} handleConfirm={handleAcceptConfirm} />
			)} */}
			</div>
		</>
	);
};

export default EstimationDetails;
