import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaCheck, FaMinus, FaTrash, FaPlus } from 'react-icons/fa';
import usePageTitle from 'hooks/usePageTitle';
import Scroller from 'ui-component/Scroller';
import { TbDatabaseExclamation } from 'react-icons/tb';
import toast from 'react-hot-toast';
import { Modal, Button, Dropdown } from 'react-bootstrap';
import NoImage from 'assets/images/no-image.png';
import moment from 'moment';



const create = () => {
	const { id } = useParams();
	const history = useNavigate();
	const [setPageTitle] = usePageTitle('Receipts');
	const [purchaseInvoice, setPurchaseInvoice] = useState(null);
	const currentYear = new Date().getFullYear();
	const [rowCounts, setRowCounts] = useState([]);
	const [show, setShow] = useState('');
	const [selectedData, setSelectedData] = useState([]);
	const [amountReceived, setAmmountReceived] = useState(0);
	const [expectedItems, setExpectedItems] = useState([]);
	const [totalExpected, setTotalExpected] = useState(0);

	useEffect(() => {
		return () => {
			setPageTitle(null);
		};
	}, []);

	useEffect(() => {


		iniateData();
	}, [id]);
	useEffect(() => {
		const total = rowCounts.reduce((sum, current) => sum + current, 0);
		setAmmountReceived(total);
	}, [rowCounts]);
	const totalQuantity = purchaseInvoice?.purchase_invoice_items?.reduce((total, row) => total + row.quantity, 0);
	const iniateData = () => {
		api.getPurchaseInvoiceView(id).then((res) => {
			setRowCounts(Array(res?.data?.purchase_invoice_items.length).fill(0));
			setPurchaseInvoice(res?.data)
			if (res?.data?.master_status?.name === 'Received') {
				const receiptItems = res?.data?.receipts.map(receipt => receipt.receipt_items).flat();
				const sumsByItemId = receiptItems.reduce((accumulator, currentItem) => {
					const itemId = currentItem.purchase_invoice_item_id;
					const quantity = currentItem.quantity;
					accumulator[itemId] = (accumulator[itemId] || 0) + quantity;
					return accumulator;
				}, {});

				setExpectedItems(sumsByItemId);
				const totalQuantity = Object.values(sumsByItemId).reduce((total, quantity) => total + quantity, 0);
				setTotalExpected(totalQuantity);
			}


		});
	}
	const handleIncrease = (index, qty, row) => {
		const updatedCounts = [...rowCounts];
		if (updatedCounts[index] < qty) {
			updatedCounts[index] += 1;
			setRowCounts(updatedCounts);
			setAmmountReceived(prevAmount => prevAmount + 1);
			setSelectedData(prevSelectedData => {
				const newData = [...prevSelectedData];
				const existingIndex = newData.findIndex(item => item.id === row.id);
				if (existingIndex !== -1) {
					newData[existingIndex].item_qty = updatedCounts[index];
				} else {
					const updatedRow = { ...row, item_qty: updatedCounts[index] };
					newData.push(updatedRow);
				}
				return newData;
			});
		} else {
			toast.error('You can not receive more than the ordered quantity');
		}

	};

	const handleDecrease = (index, row) => {
		const currentCount = rowCounts[index];
		const updatedCounts = [...rowCounts];
		updatedCounts[index] = Math.max(currentCount - 1, 0);
		setRowCounts(updatedCounts);
		setAmmountReceived(prevAmount => prevAmount > 0 ? prevAmount - 1 : 0);
		setSelectedData(prevSelectedData => {
			const newData = [...prevSelectedData];
			const existingIndex = newData.findIndex(item => item.id === row.id);
			if (existingIndex !== -1) {
				newData[existingIndex].item_qty = updatedCounts[index];
			} else {
				const updatedRow = { ...row, item_qty: updatedCounts[index] };
				newData.push(updatedRow);
			}
			return newData;
		});

	};
	const handleAddall = (index, qty, row) => {
		const updatedCounts = [...rowCounts];
		updatedCounts[index] = qty;
		setRowCounts(updatedCounts);
		setAmmountReceived(prevAmount => prevAmount + qty);
		setSelectedData(prevSelectedData => {
			const newData = [...prevSelectedData];
			const existingIndex = newData.findIndex(item => item.id === row.id);
			if (existingIndex !== -1) {
				newData[existingIndex].item_qty = updatedCounts[index];
			} else {
				const updatedRow = { ...row, item_qty: updatedCounts[index] };
				newData.push(updatedRow);
			}
			return newData;
		});
	}
	const updaterowCounts = (index, value, qty, row) => {
		const updatedCounts = [...rowCounts];
		let v = parseInt(value, 10) > 0 ? parseInt(value, 10) : 0;
		if (v < 1) {
			updatedCounts[index] = 0;
		}
		if (v <= qty) {
			updatedCounts[index] = v;
			setRowCounts(updatedCounts);
			setAmmountReceived(v);
			setSelectedData(prevSelectedData => {
				const newData = [...prevSelectedData];
				const existingIndex = newData.findIndex(item => item.id === row.id);
				if (existingIndex !== -1) {
					newData[existingIndex].item_qty = v;
				} else {
					const updatedRow = { ...row, item_qty: v };
					newData.push(updatedRow);
				}
				return newData;
			});
		}
		if (v > qty) {
			toast.error('You can not receive more than the ordered quantity');
		}

	}
	const deletePurchase = () => {
		toast.success('Purchase receipt deleted successfully');

	};
	const addToStock = () => {

		const data =
		{
			"purchase_invoice_id": id,
			"purchase_invoice_items": selectedData,
		}
		api.addReceipts(data).then((res) => {
			history(`/purchase-stock/${res}`);
		});


	}
	const addToStockWithoutIMEI = () => {
		const data =
		{
			"purchase_invoice_id": id,
			"purchase_invoice_items": selectedData,
		}
		// api.addReceiptsWithStock(data).then((res) => {
		// 	toast.success('Stock added successfully');
		// 	history(`/backorders`);
		// });


		toast.promise(
			api
				.addReceiptsWithStock(data)
				.then(
					(res) => {
						if (res.status) {
							history(`/purchaseall/view/${res?.data?.purchase_invoice_id}`);
							// invalidateEstQuery();
							// closePurchaseOrderPopup()
						}

						return res?.status ? res : null;
					},
					() => { }
				),
			{
				loading: 'Completing purchase order...',
				success: (data) => `${data.message}`,
				error: <b>Failed to receive purchase order item</b>
			}
		);
	}
	const selectProceess = () => {
		setShow(true);
	}
	const handleClose = () => setShow('');
	return (
		<>
			<div className="row">
				<div className="col-12 col-md-12">
					<div className="section-head row">

						<h2 style={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '18px' }} className='col-md-6'>
							RC{currentYear}-{id}

						</h2>
						<div className="actions col-md-6">

							<button
								type="button"
								onClick={() => deletePurchase()}
								className="btn btn-primary btn-outlined-main"
							>
								<FaTrash />
								Delete
							</button>

						</div>
					</div>
					<div className="row m-0">
						<div className="card shadow-sm rounded bg-white mb-4 p-3 p-xl-4 w-100">
							<Scroller>
								<div className="row">
									<div className="col-md-12 col-lg-12">
										<div className="" style={{ justifyContent: 'space-between' }}>
											<table className='projectmix-table-vertical'>
												<tbody>
													<tr>
														<th style={{ width: '157px' }}>Receipt</th>
														<td> {purchaseInvoice?.purchase_order_id}</td>
													</tr>
													<tr>
														<th style={{ width: '157px' }}>ETA</th>
														<td>
															{moment(purchaseInvoice?.date).format('DD MMM YYYY')}
														</td>
													</tr>
													<tr>
														<th style={{ width: '157px' }}>Warehouse</th>
														<td> {purchaseInvoice?.warehouse?.name}</td>
													</tr>
													<tr>
														<th style={{ width: '157px' }}>Currency</th>
														<td className='upper-case'> {purchaseInvoice?.currency}</td>
													</tr>
												</tbody>
											</table>
										</div>
										<div className="productsTable estlist" style={{ fontSize: '12px' }}>
											<div className="section-head mb-2">
												<h2 style={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '18px' }}>
													Products
												</h2>
											</div>
											<div className="estimation-table-area">
												<div className="table-side">
													<div className="table-responsive-xl tableData">
														<Scroller>
															<table className="table table-md text-gray-900 formActBtns border-0">
																<thead>
																	<tr>
																		<th className="align-middle" style={{ width: '50px' }}></th>
																		<th className="align-middle" style={{ width: '150px' }}>SKU</th>
																		<th className="align-middle">Product</th>
																		<th className="align-middle" style={{ width: '100px' }}>Ordered</th>
																		<th className="align-middle" style={{ width: '100px' }}>Expected</th>
																		<th className="align-middle" style={{ width: '110px' }}>Amount Receiving</th>
																	</tr>
																</thead>
																<tbody>
																	{purchaseInvoice?.purchase_invoice_items?.length > 0 ? (
																		purchaseInvoice?.purchase_invoice_items.map((row, index) => (
																			<>
																				<tr key={row?.id}>
																					<td>

																						{row?.product?.thumbnail_url ? (
																							<img src={row?.product?.thumbnail_url} style={{ width: '30px' }} alt="product" />
																						) : (
																							<img src={NoImage} style={{ width: '30px' }} alt="product" />
																						)}
																					</td>
																					<td className="align-middle">{row?.product?.sku}</td>
																					<td className="align-middle">{row?.product?.name}</td>
																					<td className="align-middle ">

																						{row?.quantity}
																					</td>
																					<td className="align-middle">

																						{purchaseInvoice?.master_status?.name === 'Received' ? row?.quantity
																							- expectedItems[row.id] || 0 : row?.quantity}

																					</td>
																					<td className="align-middle">
																						<div className="">
																							<>
																								<div className='btn-group'>
																									<button className='btn btn-default btn-sm btn-left' onClick={() => handleDecrease(index, row)}>
																										<FaMinus />
																									</button>
																									<input type='text'
																										value={rowCounts[index]}
																										className='product-amount-field'
																										onChange={(e) => {
																											const cleanedValue = e.target.value.replace(/[^0-9]/g, '');
																											updaterowCounts(index, cleanedValue, purchaseInvoice?.master_status?.name === 'Received' ? row?.quantity - expectedItems[row.id] || 0 : row?.quantity, row);
																										}}
																									>
																									</input>
																									<button className='btn btn-default btn-sm btn-right'
																										style={{ borderRight: 'none' }}
																										onClick={() => handleIncrease(index, purchaseInvoice?.master_status?.name === 'Received' ? row?.quantity - expectedItems[row.id] || 0 : row?.quantity, row)}>
																										<FaPlus />
																									</button>
																									<button className='btn btn-default btn-right' onClick={() => handleAddall(index, purchaseInvoice?.master_status?.name === 'Received' ? row?.quantity - expectedItems[row.id] || 0 : row?.quantity, row)}>
																										<FaCheck />
																									</button>

																								</div>
																							</>
																						</div>
																					</td>
																				</tr>
																			</>
																		))
																	) : (
																		<tr className="no-hover">
																			<td colSpan={9}>
																				<div className="not-found-container cstm-not-found">
																					<div className="not-found-icon">
																						<TbDatabaseExclamation />
																					</div>
																					<h6 className="not-found-text">Products not found</h6>

																				</div>
																			</td>
																		</tr>
																	)}
																</tbody>

															</table>
														</Scroller>
													</div>
												</div>
											</div>
										</div>
										{purchaseInvoice?.purchase_invoice_items?.length > 0 ?
											(
												<div className="section-footer">
													<div className="footer-left">
														<button className='btn btn-primary btn-outlined-main-primary' disabled={amountReceived === 0}
															onClick={() => selectProceess()}>
															<FaCheck /> Add products to stock
														</button>
													</div>
													<div className="footer-left">
														<div className="produt-count">
															<label htmlFor=""> Amount Expected</label>
															<label>
																{totalQuantity - totalExpected}
															</label>
														</div>
														<div className="price">
															<label htmlFor=""> Amount Receiving </label>
															<label>
																{amountReceived}
															</label>
														</div>
													</div>
												</div>
											) : (<></>)
										}


									</div>
								</div>
							</Scroller>


						</div>
					</div>
				</div>

			</div>
			<Modal show={show} onHide={handleClose} centered>
				<Modal.Body>
					<Modal.Title> <div className="text-center">Add to stock</div></Modal.Title>
					<p className='text-center mt-2'>Select the process to inbound stocks</p>
					<div className="text-center btn-add-stock">
						<button className='btn btn-primary btn-outlined-main' onClick={() => addToStock()}>Add to stock with IMEI</button>
						<button className='btn btn-primary btn-outlined-main' onClick={() => addToStockWithoutIMEI()}>Add to stock without IMEI</button>

					</div>
					<Button variant="primary" className="m-2 " onClick={handleClose}>
						Cancel
					</Button>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default create;
