import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaCheck, FaMinus, FaTrash, FaPlus } from 'react-icons/fa';
import usePageTitle from 'hooks/usePageTitle';
import Scroller from 'ui-component/Scroller';
import { TbDatabaseExclamation } from 'react-icons/tb';
import toast from 'react-hot-toast';
import { Modal, Button, Dropdown } from 'react-bootstrap';
import NoImage from 'assets/images/no-image.png';
import moment from 'moment';
import ReceiptModal from './components/ReceiptModal';
import PermissionAllowed from 'ui-component/permissions/PermissionAllowed';
import usePermissions from 'hooks/usePermissions';
import { getCurrencySymbol, numberFormatter } from 'utils/currency';


const PurchasingReceiptForm = ({ edit }) => {
	const { id } = useParams();
	const history = useNavigate();
	const [setPageTitle] = usePageTitle('Receipts');
	const [purchaseInvoice, setPurchaseInvoice] = useState(null);
    const [purchaseOrderItems, setPurchaseOrderItems] = useState([]);
	const currentYear = new Date().getFullYear();
	const [rowCounts, setRowCounts] = useState([]);
	const [show, setShow] = useState('');
	const [selectedData, setSelectedData] = useState([]);
	const [amountReceived, setAmmountReceived] = useState(0);
	const [expectedItems, setExpectedItems] = useState([]);
	const [totalExpected, setTotalExpected] = useState(0);
    const [selectedItems, setSelectedItems] = useState([]);
	const [nextReference, setNextReference] = useState();
	const [allPermissions, hasEveryPermission] = usePermissions();

	useEffect(() => {
		return () => {
			setPageTitle(null);
		};
	}, []);

	useEffect(() => {
		initializeData();
    }, [id]);
    
	useEffect(() => {
		const total = rowCounts.reduce((sum, current) => sum + current, 0);
		setAmmountReceived(total);
    }, [rowCounts]);
    
	const totalQuantity = purchaseInvoice?.purchase_invoice_items?.reduce((total, row) => total + row.quantity, 0);
	const initializeData = () => {


		if (edit)
		{
				api.getReceiptDetails(id).then((res) => {
					if (res?.status) {
						setNextReference(res?.data?.receipt_number)



						if (res?.data?.receipt_items.length > 0) {
							// setPurchaseOrderItems(res?.data?.purchase_invoice_items)
		
							const info = {
								id: res?.data?.id,
								purchase_order_id: res?.data?.purchase_order_id,
								purchase_invoice_id: res?.data?.purchase_invoice_id,
								date: res?.data?.date,
								currency: res?.data?.currency,
								supplier_id: res?.data?.supplier_id,
								supplier_name: res?.data?.supplier_name,
								warehouse_id: res?.data?.warehouse_id,
								warehouse_name: res?.data?.warehouse_name,
								created_by: res?.data?.created_by,
							}
							setPurchaseInvoice(info)
							const populate = res?.data?.receipt_items?.map(item => {
								const total = item.quantity * parseFloat(item.unit_price ?? 0);
								return {
									id: item.id,
									product_id: item.product_id,
									sku: item?.sku,
									product_name: item?.product_name,
									thumbnail_url: item?.thumbnail_url,
									quantity: item?.quantity,
									unit_price: item?.unit_price,
									total: total,
									limit_quantity: 0,
									received_quantity: 0,
									receiving_quantity: 0,
								};
							});
							setSelectedItems(populate);
						}
					}
				});
		} else {


			api.getNextReferenceNumber('receipt').then((res) => {
				const next_reference_no = res?.data?.next_reference_no
				setNextReference(next_reference_no)
			});

			api.getPurchaseOrderDetails(id).then((res) => {
				setRowCounts(Array(res?.data?.purchase_invoice_items.length).fill(0));
				console.log('res?.data', res?.data)
				// setPurchaseInvoice(res?.data)

				const info = {
					id: res?.data?.id,
					purchase_order_id: res?.data?.purchase_order_id,
					purchase_invoice_id: res?.data?.id,
					date: res?.data?.date,
					currency: res?.data?.currency,
					supplier_id: res?.data?.supplier_id,
					supplier_name: res?.data?.supplier?.name,
					warehouse_id: res?.data?.warehouse_id,
					warehouse_name: res?.data?.warehouse?.name,
					created_by: res?.data?.created_by,
					purchase_invoice_items: res?.data?.purchase_invoice_items
				}
				setPurchaseInvoice(info)
				if (res?.data?.master_status?.name === 'Received') {
					const receiptItems = res?.data?.receipts.map(receipt => receipt.receipt_items).flat();
					const sumsByItemId = receiptItems.reduce((accumulator, currentItem) => {
						const itemId = currentItem.purchase_invoice_item_id;
						const quantity = currentItem.quantity;
						accumulator[itemId] = (accumulator[itemId] || 0) + quantity;
						return accumulator;
					}, {});

					setExpectedItems(sumsByItemId);
					const totalQuantity = Object.values(sumsByItemId).reduce((total, quantity) => total + quantity, 0);
					setTotalExpected(totalQuantity);
				}
				
				if (res?.data?.purchase_invoice_items.length > 0) {
					// setPurchaseOrderItems(res?.data?.purchase_invoice_items)


					const populate = res?.data?.purchase_invoice_items?.map(item => {
					const limitQuantity = item.quantity - (item.received_quantity ?? 0);
						return {
							id: item.id,
							product_id: item.product?.id,
							sku: item.product?.sku,
							product_name: item.product?.name,
							thumbnail_url: item.product?.thumbnail_url,
							quantity: item.quantity,
							limit_quantity: limitQuantity,
							received_quantity: item.received_quantity ?? 0,
							receiving_quantity: 0,
						};
					});
					setSelectedItems(populate);
				}
			});
		}

	}
    const handleIncrease = (productId) => {
        
        const itemCollected = selectedItems.map((item) => {
            if (item.product_id === productId) {
                const receivingQuantity = item.receiving_quantity + 1;
                if (receivingQuantity > item.limit_quantity) {
                    toast.error('You can not receive more than the ordered quantity');
                    return item;
                }
                return { ...item, receiving_quantity: item.receiving_quantity + 1 };
            }
            return item;
        });
        setSelectedItems(itemCollected);
	};

    const handleDecrease = (productId) => {
        const itemCollected = selectedItems.map((item) => {
            if (item.product_id === productId) {
                const receivingQuantity = item.receiving_quantity - 1;
                if (receivingQuantity < 0) {
                    toast.error('You cannot receive less than 0');
                    return item;
                }
                return { ...item, receiving_quantity: receivingQuantity };
            }
            return item;
        });
        setSelectedItems(itemCollected);

	};

    const handleChangeQuantity = (productId, value) => {
        const receivingQuantity = parseInt(value);
        if (isNaN(receivingQuantity)) {
            const itemCollected = [...selectedItems];
            const itemIndex = selectedItems.findIndex((item) => item.product_id === productId);
            itemCollected[itemIndex].receiving_quantity = 0;
            setSelectedItems(itemCollected);
        } else {
            const itemCollected = selectedItems.map((item) => {
                if (item.product_id === productId) {
                    if (receivingQuantity < 0) {
                        toast.error('You cannot receive less than 0');
                        return item;
					} else if (receivingQuantity > item?.limit_quantity) {

						toast.error('You can not receive more than the ordered quantity');
						return { ...item, receiving_quantity: item?.limit_quantity };
                    }
                    return { ...item, receiving_quantity: receivingQuantity };
                }
                return item;
            });
            setSelectedItems(itemCollected);
        }
    }
	const handleAddAll = (productId) => {
        const collection = [...selectedItems];
        const itemCollected = collection?.map((item) => {
            if (item?.product_id === productId) {
                return {
                    id: item.id,
                    product_id: item.product_id,
                    sku: item.sku,
                    product_name: item.product_name,
                    thumbnail_url: item.thumbnail_url,
                    quantity: item.quantity,
                    limit_quantity: item.limit_quantity,
                    received_quantity: item.received_quantity ?? 0,
                    receiving_quantity: item.quantity - (item.received_quantity ?? 0),
                };
            }
            return item;
        });
        setSelectedItems(itemCollected);
	}
	const updaterowCounts = (index, value, qty, row) => {
		const updatedCounts = [...rowCounts];
		let v = parseInt(value, 10) > 0 ? parseInt(value, 10) : 0;
		if (v < 1) {
			updatedCounts[index] = 0;
		}
		if (v <= qty) {
			updatedCounts[index] = v;
			setRowCounts(updatedCounts);
			setAmmountReceived(v);
			setSelectedData(prevSelectedData => {
				const newData = [...prevSelectedData];
				const existingIndex = newData.findIndex(item => item.id === row.id);
				if (existingIndex !== -1) {
					newData[existingIndex].item_qty = v;
				} else {
					const updatedRow = { ...row, item_qty: v };
					newData.push(updatedRow);
				}
				return newData;
			});
		}
		if (v > qty) {
			toast.error('You can not receive more than the ordered quantity');
		}

	}
	const deletePurchase = () => {
		toast.success('Purchase receipt deleted successfully');

	};
	const addToStock = () => {

		const data =
		{
			"purchase_invoice_id": id,
			"purchase_invoice_items": selectedData,
		}
		api.addReceipts(data).then((res) => {
			history(`/purchase-stock/${res}`);
		});


	}
	const addToStockWithoutIMEI = () => {
		const data =
		{
			"purchase_invoice_id": id,
			"purchase_invoice_items": selectedData,
		}
		// api.addReceiptsWithStock(data).then((res) => {
		// 	toast.success('Stock added successfully');
		// 	history(`/backorders`);
		// });


		toast.promise(
			api
				.submitPurchaseOrderReceipt(data)
				.then(
					(res) => {
						if (res.status) {
							history(`/purchaseall/view/${res?.data?.purchase_invoice_id}`);
							// invalidateEstQuery();
							// closePurchaseOrderPopup()
						}

						return res?.status ? res : null;
					},
					() => { }
				),
			{
				loading: 'Completing purchase order...',
				success: (data) => `${data.message}`,
				error: <b>Failed to receive purchase order item</b>
			}
		);
	}
	const handleProcessReceipt = () => {
		setShow(true);
	}
    
    const handleClose = () => {
        setShow('')
    }

    const submitReceiptForm = (type) => {
        if (type === 'without_imei') {

            let data = {
                purchase_invoice: purchaseInvoice,
                items: selectedItems
            };
            
    
            toast.promise(
                api
                    .submitPurchaseOrderReceipt(data)
                    .then(
                        (res) => {
                            if (res.status) {
                                history(`/purchasing/view/${purchaseInvoice?.id}`);
                                // invalidateEstQuery();
                                // closePurchaseOrderPopup()
                            }
    
                            return res?.status ? res : null;
                        },
                        () => { }
                    ),
                {
                    loading: 'Completing purchase order...',
                    success: (data) => `${data.message}`,
                    error: <b>Failed to receive purchase order item</b>
                }
            );
        } else {
            
        }
    }
    
    const handleAddToStockWithoutImei = () => {

	}
	
	const viewData = (type, id) => {
		if (type === 'products') {
			window.open(`/products/${id}`, '_blank');
			// history(`/products/${id}`);
		} else if (type === 'purchase_invoice') {
			window.open(`/purchasing/view/${id}`, '_blank');
		} else if (type === 'supplier') {
			window.open(`/contacts/${id}`, '_blank');
		} else if (type === 'warehouse') {
			window.open(`/warehouses/${id}`, '_blank');
		}
	};

	return (
		<>
			<div className="row">
				<div className="col-12 col-md-12">
					<div className="section-head row">

						<h2 style={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '18px', marginLeft: '-12px' }} className='col-md-6'>
							{nextReference}
						</h2>
						<div className="actions col-md-6">
							{/* {
								edit && (
									<>
										<button
											type="button"
											onClick={() => deletePurchase()}
											className="btn btn-primary btn-outlined-main"
										>
											<FaTrash />
											Delete
										</button>
									</>
								)
							} */}

						</div>
					</div>
					<div className="row m-0">
						<div className="card shadow-sm rounded bg-white mb-4 p-3 p-xl-4 w-100">
							<Scroller>
								<div className="row">
									<div className="col-md-12 col-lg-12">
										<div className="" style={{ justifyContent: 'space-between' }}>
											<table className='projectmix-table-vertical'>
												<tbody>
													<tr>
														<th style={{ width: '157px' }}>Receipt</th>
														<td>{nextReference}</td>
													</tr>
													<tr>
														<th style={{ width: '157px' }}>Purchase Order</th>
														<td
															className={hasEveryPermission(['View Purchase Invoice (Button)']) ? 'cursor-pointer text-primary' : ''}
															onClick={() => {
																if (hasEveryPermission(['View Purchase Invoice (Button)'])) {
																	viewData('purchase_invoice', purchaseInvoice?.purchase_invoice_id);
																}
															}}
														>
															{purchaseInvoice?.purchase_order_id}
														</td>
													</tr>
													<tr>
														<th style={{ width: '157px' }}>Supplier</th>
														<td
															className={hasEveryPermission(['View Contact (Button)']) ? 'cursor-pointer text-primary' : ''}
															onClick={() => {
																if (hasEveryPermission(['View Contact (Button)'])) {
																	viewData('supplier', purchaseInvoice?.supplier_id);
																}
															}}
														>
															{purchaseInvoice?.supplier_name}
														</td>
													</tr>
													<tr>
														<th style={{ width: '157px' }}>Warehouse</th>
														<td
															className={hasEveryPermission(['View Warehouse (Button)']) ? 'cursor-pointer text-primary' : ''}
															onClick={() => {
																if (hasEveryPermission(['View Warehouse (Button)'])) {
																	viewData('warehouse', purchaseInvoice?.warehouse_id);
																}
															}}
														>
															{purchaseInvoice?.warehouse_name}
														</td>
													</tr>
													<tr>
														<th style={{ width: '157px' }}>Completed At</th>
														<td>
															{moment(purchaseInvoice?.date).format('DD MMM YYYY')} {' '}
															{purchaseInvoice?.created_by && (`by ${purchaseInvoice?.created_by}`)}
														</td>
													</tr>
													{/* <tr>
														<th style={{ width: '157px' }}>Warehouse</th>
														<td> {purchaseInvoice?.warehouse?.name}</td>
													</tr> */}
													<tr>
														<th style={{ width: '157px' }}>Currency</th>
														<td className='upper-case'> {purchaseInvoice?.currency}</td>
													</tr>
												</tbody>
											</table>
										</div>
										<div className="productsTable estlist" style={{ fontSize: '12px' }}>
											<div className="section-head mb-2">
												<h2 style={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '18px' }}>
													Products
												</h2>
											</div>
											<div className="estimation-table-area">
												<div className="table-side">
													<div className="table-responsive-xl tableData">
														{
															edit ? (
																<>
																				
																	<Scroller>
																		<table className="table pm-table">
																			<thead>
																				<tr>
																					<th className="align-middle" style={{ width: '' }}></th>
																					<th className="align-middle" style={{ width: '' }}>SKU</th>
																					<th className="align-middle">Product</th>
																					<th className="align-middle" style={{ width: '' }}>Received</th>
																					<th className="align-middle" style={{ width: '' }}>Value</th>
																					<th className="align-middle" style={{ width: '' }}>Total</th>
																				</tr>
																			</thead>
																			<tbody>
																				{selectedItems?.length > 0 ? (
																					selectedItems.map((item, index) => (
																						<>
																							<tr key={item?.id}>
																								<td>
																									{item?.thumbnail_url ? (
																										<img src={item.thumbnail_url} style={{ width: '40px' }} alt="product" />
																									) : (
																										<img src={NoImage} style={{ width: '40px' }} alt="product" />
																									)}
																								</td>
																								<td
																									className={hasEveryPermission(['View Product (Button)']) ? 'cursor-pointer text-primary align-middle' : 'align-middle'}
																									onClick={() => {
																										if (hasEveryPermission(['View Product (Button)'])) {
																											viewData('products', item.product_id);
																										}
																									}}
																								>
																									{item?.sku}
																								</td>
																								<td className="align-middle">{item?.product_name}</td>
																								<td className="align-middle">{item?.quantity}</td>
																								<td className="align-middle">
																									{getCurrencySymbol(purchaseInvoice?.currency)}{' '}
																									{parseFloat(item?.unit_price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
																								</td>
																								<td className="align-middle">
																									{getCurrencySymbol(purchaseInvoice?.currency)}{' '}
																									{parseFloat(item?.total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
																								</td>
																							</tr>
																						</>
																					))
																				) : (
																					<tr className="no-hover">
																						<td colSpan={6}>
																							<div className="not-found-container cstm-not-found">
																								<div className="not-found-icon">
																									<TbDatabaseExclamation />
																								</div>
																								<h6 className="not-found-text">Products not found</h6>

																							</div>
																						</td>
																					</tr>
																				)}
																			</tbody>
																			<tfoot>
																				<tr>
																					<td colSpan={3}></td>
																					<td>
																						{selectedItems?.length > 0 && (
																							selectedItems.reduce((sum, current) => sum + current.quantity, 0)
																						)} pcs
																					</td>
																					<td></td>
																					<td>
																						{getCurrencySymbol(purchaseInvoice?.currency)}{' '}
																						{selectedItems?.length > 0 && (
																							parseFloat(selectedItems.reduce((sum, current) => sum + current.total, 0)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
																						)} 
																					</td>
																				</tr>
																			</tfoot>
																		</table>
																	</Scroller>
																</>

															): (
																<>
																	<Scroller>
																		<table className="table table-md text-gray-900 formActBtns border-0">
																			<thead>
																				<tr>
																					<th className="align-middle" style={{ width: '50px' }}></th>
																					<th className="align-middle" style={{ width: '150px' }}>SKU</th>
																					<th className="align-middle">Product</th>
																					<th className="align-middle" style={{ width: '100px' }}>Ordered</th>
																					<th className="align-middle" style={{ width: '100px' }}>Expected</th>
																					<th className="align-middle" style={{ width: '110px' }}>Amount Receiving</th>
																				</tr>
																			</thead>
																			<tbody>
																				{selectedItems?.length > 0 ? (
																					selectedItems.map((item, index) => (
																						<>
																							<tr key={item?.id}>
																								<td>
																									{item?.thumbnail_url ? (
																										<img src={item.thumbnail_url} style={{ width: '30px' }} alt="product" />
																									) : (
																										<img src={NoImage} style={{ width: '30px' }} alt="product" />
																									)}
																								</td>
																								<td 
																									className={hasEveryPermission(['View Product (Button)']) ? 'cursor-pointer text-primary align-middle' : 'align-middle'}
																									onClick={() => {
																										if (hasEveryPermission(['View Product (Button)'])) {
																											viewData('products', item.product_id);
																										}
																									}}
																								>
																									{item?.sku}
																								</td>
																								<td className="align-middle">
																									{item?.product_name}
																								</td>
																								<td className="align-middle">{item?.quantity}</td>
																								<td className="align-middle">
																									{/* {purchaseInvoice?.master_status?.name === 'Received' ? item?.quantity - item?.received_quantity || 0 : item?.quantity} */}
																									{item?.limit_quantity}
																								</td>
																								<td className="align-middle">
																									<div className="">
																										<>
																											<div className='btn-group'>
																												<button className='btn btn-default btn-sm btn-left' onClick={() => handleDecrease(item?.product_id)}>
																													<FaMinus />
																												</button>
																												<input type='text'
																													value={item?.receiving_quantity}
																													className='product-amount-field'
																													// onChange={(e) => {
																													// 	const cleanedValue = e.target.value.replace(/[^0-9]/g, '');
																													// 	updaterowCounts(index, cleanedValue, purchaseInvoice?.master_status?.name === 'Received' ? row?.quantity - expectedItems[row.id] || 0 : row?.quantity, row);
																													// }}
																													onChange={(e) => handleChangeQuantity(item?.product_id, e.target.value)}
																												>
																												</input>
																												<button className='btn btn-default btn-sm btn-right'
																													style={{ borderRight: 'none' }}
																													onClick={() => handleIncrease(item?.product_id)}
																												>
																													<FaPlus />
																												</button>
																												{/* <button className='btn btn-default btn-right' onClick={() => handleAddall(index, purchaseInvoice?.master_status?.name === 'Received' ? item?.quantity - expectedItems[row.id] || 0 : row?.quantity, row)}> */}
																												<button
																													className='btn btn-default btn-right'
																													onClick={() => handleAddAll(item?.product_id)}
																													// onClick={() => handleAddall(index, purchaseInvoice?.master_status?.name === 'Received' ? item?.quantity - expectedItems[row.id] || 0 : row?.quantity, row)}
																												>
																													<FaCheck />
																												</button>
			
																											</div>
																										</>
																									</div>
																								</td>
																							</tr>
																						</>
																					))
																				) : (
																					<tr className="no-hover">
																						<td colSpan={9}>
																							<div className="not-found-container cstm-not-found">
																								<div className="not-found-icon">
																									<TbDatabaseExclamation />
																								</div>
																								<h6 className="not-found-text">Products not found</h6>
			
																							</div>
																						</td>
																					</tr>
																				)}
																			</tbody>
			
																		</table>
																	</Scroller>
																</>
															)
														}
													</div>
												</div>
											</div>
										</div>
										{purchaseInvoice?.purchase_invoice_items?.length > 0 ?
											(
												<div className="section-footer">
													<div className="footer-left">
                                                        <button
                                                            className='btn btn-primary btn-outlined-main-primary'
                                                            disabled={isNaN(parseInt(selectedItems.reduce((sum, current) => sum + parseInt(current.receiving_quantity), 0))) ? 0 : parseInt(selectedItems.reduce((sum, current) => sum + parseInt(current.receiving_quantity), 0)) === 0}
                                                            onClick={() => handleProcessReceipt()}
                                                        >
															<FaCheck /> Add products to stock
														</button>
													</div>
													<div className="footer-left">
														<div className="produt-count">
															<label htmlFor=""> Amount Expected</label>
															<label>
                                                                { selectedItems.reduce((sum, current) => sum + current.quantity, 0) - selectedItems.reduce((sum, current) => sum + current.received_quantity, 0)  }
															</label>
														</div>
														<div className="price">
															<label htmlFor=""> Amount Receiving </label>
															<label>
                                                                { isNaN(parseInt(selectedItems.reduce((sum, current) => sum + parseInt(current.receiving_quantity), 0))) ? 0 : parseInt(selectedItems.reduce((sum, current) => sum + parseInt(current.receiving_quantity), 0)) }
															</label>
														</div>
													</div>
												</div>
											) : (<></>)
										}


									</div>
								</div>
							</Scroller>


						</div>
					</div>
				</div>

            </div>
            {
                show && (
                    <>
                        
                        <ReceiptModal
                            openPopup={show}
                            purchaseInvoice={purchaseInvoice}
                            handleClose={handleClose}
                            submitReceiptForm={submitReceiptForm}
                            selectedItems={selectedItems}
                        />
                    </>
                )
            }
			{/* <Modal show={show} onHide={handleClose} centered>
				<Modal.Body>
					<Modal.Title> <div className="text-center">Add to stock</div></Modal.Title>
					<p className='text-center mt-2'>Select the process to inbound stocks</p>
					<div className="text-center btn-add-stock">
						<button className='btn btn-primary btn-outlined-main' onClick={() => addToStock()}>Add to stock with IMEI</button>
						<button className='btn btn-primary btn-outlined-main' onClick={() => addToStockWithoutIMEI()}>Add to stock without IMEI</button>

					</div>
					<Button variant="primary" className="m-2 " onClick={handleClose}>
						Cancel
					</Button>
				</Modal.Body>
			</Modal> */}
		</>
	);
};

export default PurchasingReceiptForm;
